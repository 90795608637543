import ApiService from './ApiService';



const FuseService = {

  fetchSettings() {
    return ApiService.get(`/fuse/config`);
  },

  fetchSettingsSchema() {
    return ApiService.get(`/fuse/configSchema`)
  },
  fetchSettingsUISchema() {
    return ApiService.get(`/fuse/configUISchema`)
  },
  updateSettings(data) {
    return ApiService.put(`/fuse/config`, data);
  },


};

export default FuseService
