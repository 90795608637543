import React, { useEffect, useState } from 'react';
import s from './styles.module.scss';
import TokenService from '../../services/TokenService';
import AmazonOutlined from '@ant-design/icons/lib/icons/AmazonOutlined';
import { Card, Button } from 'antd';

const REDIRECT_URL = process.env.REACT_APP_AUTH_REDIRECT_URL
const AUTH_URL = process.env.REACT_APP_AUTH_URL
const CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID
const RESPONSE_TYPE = process.env.REACT_APP_AUTH_RESPONSE_TYPE
const SCOPE = process.env.REACT_APP_AUTH_SCOPE


function SignInForm() {
  const url = `${AUTH_URL}/authorize?client_id=${CLIENT_ID}&response_type=${RESPONSE_TYPE}&scope=${SCOPE}&redirect_uri=${REDIRECT_URL}`;

  const [sha, setSha] = useState('');
  useEffect(() => {
    TokenService.generateAndSaveSha256().then((data) => {
      setSha(data);
    });
  }, []);

  return (
    <Card className={s.card} title="Sign In">
      <a className={s.link} href={sha && url + `&code_challenge_method=S256&code_challenge=${sha}`}>
        <Button type="primary" size="large" loading={sha === null} >
          <AmazonOutlined style={{color: "#4E89FF !important"}}/>
          Sign In with Amazon Cognito
        </Button>
      </a>
    </Card>
  );
}

export default SignInForm;
