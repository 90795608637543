import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';
import Header from '../Header';
import s from './styles.module.scss';
import React from 'react';

const { Content } = Layout;

function MainLayout() {
  return (
    <Layout className={s.MainLayout}>
      <Layout>
        <Header />
        <Content className={s.content}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
}

export default MainLayout;
