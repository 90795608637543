import { Radio, Select } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRegions } from '../../../../reducers/dashboard';
import { DefaultState, Filter } from '../../../../types/rootState';
import s from './styles.module.scss'
import { LanguageTwoTone } from '@mui/icons-material';

const { Option } = Select;

interface Regions {
  dashboard: {
    regionsXbox: DefaultState
  }
}

const SelectRegion: React.FC<Filter> = ({ onChange, value }) => {

  const dispatch = useDispatch();

  const regions = useSelector((state: Regions) => state.dashboard.regionsXbox);

  useEffect(() => {
    if (!regions.payload) {
      dispatch(fetchRegions());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const regionsData = regions && regions.payload && regions.payload

  return (
    <>
      <div>
      <LanguageTwoTone style={{fontSize: '12px', marginRight: '4px', color: 'rgb(24, 144, 255)'}} />
        <b>Regions</b>
      </div>
      <div className={s.regionSelect}>
       <Select
          value={value}
          mode="multiple"
          style={{ width: '100%' }}
          placeholder="Select"
          onChange={onChange}
        >
          {regionsData && regionsData.map((item) => <Option key={item}>{item}</Option>)}
        </Select>
      </div>
    </>
  );
}

export default SelectRegion;
