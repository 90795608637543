import { withJsonFormsControlProps } from '@jsonforms/react';
import CustomNumberInput from './CustomInputNumber';

const RatingControl = (props, { data, handleChange, path, label, schema } ) => (

  <CustomNumberInput
  props={props}
    path={path}
    label={label}
    value={data}
    updateValue={(newValue) => handleChange(path, newValue)}
  />
);

export default withJsonFormsControlProps(RatingControl);
