import React, { useCallback } from 'react';
import s from './styles.module.scss'
import moment from 'moment';

interface ColumnStartedAt {
  time: number,
};

const ColumnStartedAt: React.FC<ColumnStartedAt> = ({ time }) => {

  return (
    <>
    <br />
    <div className={s.withTwoLine}>
        <span>{moment(time).format('HH:mm:ss')}</span>
        </div>
    
    </>
  );
}

export default ColumnStartedAt;
