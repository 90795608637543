import React, { useCallback} from 'react';
import { Cascader } from 'antd';
import { debounce } from 'lodash';
import { lookupDigisel } from '../../../../reducers/dashboard';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import clearSpecialCharacters from '../../../../Utils/clearSpecialCharacters';
import { CreateMapping } from '../../../../types/rootState';

interface LookupState {
  dashboard: {
    lookDigi: any
  }
}


const ProductCreateDigiseller: React.FC<CreateMapping> = ({setMktProductId, setParentCategoryId, setCategoryId}) => {

  const dispatch = useDispatch()

  const lookup = useSelector((state: LookupState) => state?.dashboard?.lookDigi, shallowEqual);

  const onSearch = useCallback(
    (() => {
      const debouncedLookup = debounce((value) => {
        dispatch(lookupDigisel(value))
      }, 700);

      return (query: string) => {
        const cleared = clearSpecialCharacters(query);
        if (cleared.length >= 3) {
          debouncedLookup(cleared);
        }
      };
    })(),
    [],
  );

  const onChange = (value: any) => {
    setMktProductId(value[0])
    setParentCategoryId(value[0]);

    const lastLevelId = value.length && value.length - 1
    setCategoryId(value[lastLevelId])
    
  };

return (
 
    <>
    <Cascader
      size="large"
      fieldNames={{ label: 'name', value: 'id', children: 'children' }}
      options={lookup?.payload}
      onChange={onChange}
      showSearch
      onSearch={onSearch}
      />
      </>
   
);

};

export default ProductCreateDigiseller
