import { Button, Col, Row, Table, Tooltip, message } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import XboxPrice from '../../dashboard/Columns/XboxPrice'
import s from './styles.module.scss'
import ColumnMarkets from '../../dashboard/Columns/ColumnMarkets'
import ColumnPriceG2A from '../../dashboard/Columns/Price'
import marketToString from '../../../Utils/marketToString'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ExclamationCircleTwoTone from '@ant-design/icons/lib/icons/ExclamationCircleTwoTone';
import ActionButtons from '../../../components/ActionButtons'
import theme from '../../../Utils/jsonTheme'
import { ProductTableProps } from '../../../types/rootState'
import { approveMapping, declineMapping } from '../../../reducers/automapper'
import { MarketsFetch } from '../../../reducers/dashboard'
import MinCurrentProfit from '../../../components/MinProfit'
import MinPriceRatio from '../../../components/MinPriceRatio'

const CURRENCY = "EUR"

const AutoMapperTable: React.FC<ProductTableProps> = ({list, isLoading, pagination, onChange, afterUpdate, filters, onSelectChange, selectedRowKeys, onFilter, query}) => {


  const dispatch = useDispatch();

  useEffect(() => {
      dispatch(MarketsFetch())
      //@ts-ignore
      .catch((error) => {
        message.error('Please Sign In');
    })
  }, [dispatch]);

  const markets = useSelector((state: any) => state.dashboard.markets.payload, shallowEqual);

  

  const handleChangeAction = useCallback((mktId: number, mktProductId: string, data: string) => {
    const parsedData = JSON.parse(data)
    const params = {
      data: parsedData,
      filters: { ...filters },
      query,
      mktId,
      mktProductId
    };
    if (!mktProductId) {
      message.error('Market product ID is absent.');
    } else {
      //@ts-ignore
        dispatch(changeAction(params)).then(() => {
        message.success('Action has been added!');
        afterUpdate();
      }).catch((error: string) => {
        console.log(error);
        message.error('Can\'t change action.');
      });
    }
  }, [dispatch, afterUpdate, filters, query]);


  const handleApproveProposal = useCallback((id, region, market, mktProductId) => {
    
    const data = {
      pltId: 2,
      mktId: market,
      mktProductId: mktProductId, // handle After
      pltProductId: id,
      pltRegion: region,
      // isActive: active,
      // enabled: enabled,
      setDefault: [
        "profit"
      ],
        }
    dispatch(approveMapping(data))
  }, [])

  const handleDisapproveProposal = useCallback((id, region) => {
    const data = [{ pltId: 2, pltRegion: region, pltProductId: id, status: 1 }]
    dispatch(declineMapping(data))
  }, [])

  function GetColumns(): any {
    return [
      {
        title: 'ID/Name',
        dataIndex: 'pltProductId',
        key: 'pltProductId',
        width: 200,
        align: 'center',
        sorter: (a, b) => a.pltName.localeCompare(b.pltName),
        render: (_, record) => {
          return (
           <div className={s.withTwoLine}>
              <a target="_blank" rel="noopener noreferrer" href={record?.pltUrl} className={s.linkToMarket}>
                {record?.pltName}
              </a>
              <div className={s.idRow}>
                {record?.pltProductId?.toUpperCase()}
              </div>
          </div>
              )
        },

      },
      {
        title: 'Reg',
        dataIndex: 'pltRegion',
        key: 'pltRegion',
        align: 'center',
        width: 70,
        render: (_, record) => {
          return <div className="inner-rows__wrapper">
                {(record.pltRegion).toUpperCase()}
                  </div>
        },
      },
      {
        title: 'XBOX Price',
        dataIndex: 'pltPrice',
        key: 'mktRows[0].price.ext.pltPrice',
        width: 120,
        align: 'center',
        className: 'inner-rows',
        render: (_, record) => {
          //@ts-ignore
          const rows = record.mktRows && record.mktRows[0]
          return <>
          {/* {rows.map((item, index) =>  */}
          <div className={s.innerRowContent}> 
              <XboxPrice
              priceInStock={rows?.price?.ext?.priceInStock}
              useStockPrice={rows?.price?.ext?.useStockPrice}
              pltPriceCur={rows?.price?.ext?.pltPriceCur}
              pltPrice={rows?.price?.ext?.pltPrice}
              pltCurrency={rows?.price?.ext?.pltCurrency}
              discount={rows?.price?.ext?.discount}
              defaultPrice={rows?.price?.ext?.pltDfltPrice}
              />
            </div>
          {/* )} */}
        </>
        },
      },
       { 
        title: 'Markets',
        dataIndex: 'rows.mktRows[0].mktId',
        key: 'rows.mktRows[0].mktId',
        width: 270,
        align: 'center',
        render: (_, record) => {
          const rows = record.mktRows && record.mktRows
          return <>
          {rows?.map((item, index) => { 
          const market = marketToString(item.mktId, markets)
          return <div key={index}>
            <ColumnMarkets autoMapper={true} mktId={item?.mktId} mktProductId={item?.mktProductId} marketLink={item?.mktUrl} market={market} enabled={item?.enabled} afterUpdate={afterUpdate} />
          </div>
          })}
          
          </>
      },
      },
      {
        title: 'Name / Product id',
        dataIndex: 'name',
        key: 'name',
        align: 'center',
        width: 250,
        render: (_, record) => {
          const rows = record.mktRows && record.mktRows
          return <>
            {/* @ts-ignore */}
            {rows?.map((item, index) => {
              const length = item?.mktName?.length
            return  <Row key={index} className={s.row54} justify={"center"}>
            <Col span={24}>
              <div>{item.mktName}</div>
            </Col>
            </Row>
          })}
        </>
        },
       },
        {
                title: 'Price',
                dataIndex: 'price',
                key: 'price',
                align: 'center',
                width: 120,
                render: (_, record) => {
                  const rows = record.mktRows && record.mktRows
                  return <>
                  {rows.map((item, index) => 
                  <Row key={index} className={s.row54}>
                  <Col span={24} >
                  <ColumnPriceG2A alert={''} priceForBuyers={item.price.buyerPrice} price={item.price.mktPrice} CURRENCY={CURRENCY} />
                    {item.price.alert &&
                    <Tooltip placement="topLeft" title={item.price.alert}>
                      <ExclamationCircleTwoTone twoToneColor="#eb2f96" className={s.fr}/>
                    </Tooltip>
                    }
                    </Col>
                    </Row>
                    
                  )}
                </>
                },
                shouldCellUpdate: (record, prevRecord) => !shallowEqual(record, prevRecord)
              },
              {
                title: 'Ratio',
                align: 'center',
                width: 200,
                render: (_, record) => {
                  const rows = record.mktRows && record.mktRows
                  return <>
                  {rows.map((item, index) => 
                  <Row key={index} className={s.row60}>
                      <Col span={24}>
                      <MinCurrentProfit
                      isDefault={item?.formula?.isDefault} 
                      profitMin={item?.formula?.isDefault ? Math.round(item?.formula?.minProfitCalc * 100) : Math.round((item.price?.ext?.profitMin as number) * 100)} 
                      currentProfit={item?.formula?.isDefault ? Math.round(item?.formula?.maxProfitCalc * 100) : Math.round(item.price?.ext?.profitMax as number * 100)} 
                      className="cursor-pointer" 
                      />
                            <br />
                            <MinPriceRatio
                            priceMin={item?.price?.ext?.mktPriceMin} 
                            priceMax={item?.price?.ext?.mktPriceMax} 
                            className="cursor-pointer" 
                            />
                      </Col>
                    </Row>
              )}
                </>
                },
                shouldCellUpdate: (record, prevRecord) => !shallowEqual(record, prevRecord)
              },
      {
        title: 'Action',
            dataIndex: 'isActive',
            key: 'isActive',
            width: 220,
            align: 'center',
            className: 'inner-rows',
            render: (_, record) => {
              const rows = record.mktRows && record.mktRows
              return <div className="inner-rows__wrapper">
                {rows.map((item, index) =>
                <Row key={index} className={s.row60}>
                      <Col span={24}>
                        <span key={index} className="inner-row__content">
                          <ActionButtons
                            hasStock={item?.hasStock}
                            isActive={item?.isActive}
                            handleClick={(value) => handleChangeAction(item?.mktId, item?.mktProductId, value)}
                          />
                        </span>
                    </Col>
                  </Row>
                  )}
              </div>
            },
            shouldCellUpdate: (record, prevRecord) => !shallowEqual(record, prevRecord)
          },
          {
            title: 'Approve',
            dataIndex: 'pltProductId',
            key: 'pltProductId',
            width: 200,
            align: 'center',
            render: (_, record) => {
              const rows = record.mktRows && record.mktRows
              return <div className="inner-rows__wrapper">
              {rows.map((item, index) =>
               <div key={index} className={s.approvalButtons}>
                {/* @ts-ignore */}
                  <Button disabled={item?.mktProductId === "0"} onClick={() => handleApproveProposal(record?.pltProductId, record?.pltRegion, item?.mktId, item?.mktProductId)} type="primary">Approve</Button>
                  {/* @ts-ignore */}
                  <Button onClick={() => handleDisapproveProposal(record?.pltProductId, record?.pltRegion, item?.mktId, item?.mktProductId)} type="primary" danger>Disapprove</Button>
              </div>
              )}
              </div>
            }
          }
      
    ]
  }


const [columns, setColumns] = useState(GetColumns())


  return (
    <Table
      virtual
      // rowClassName={getRowClassName}
      bordered
      rowKey={(record) => `${record.mktRows.map((row) => [row.mktId, row.mktProductId])}`}
      scroll={{ y: 850, x: 1200 }}
      size="middle"
      dataSource={list}
      columns={columns}
      loading={isLoading}
      pagination={pagination}
      onChange={(a, b, c) => onChange(a, b, c)}
      // rowSelection={{
      //   columnWidth: 48,
      //   fixed: true,
      //   selectedRowKeys,
      //   onChange: (selected) => onSelectChange(selected),
      // }}
    />
  )
}

export default AutoMapperTable
