import { Radio } from 'antd';
import React from 'react';
import { Filter } from '../../../../types/rootState';
import s from './styles.module.scss'

interface WithAllWithout extends Filter {
  title: string
  icon: any
}

const WithAllWithoutGroupButton: React.FC<WithAllWithout> = ({ onChange, value, title, icon })  =>{
  return (
    <>
      <div>
        {icon}
        <b>{title}</b>
      </div>
      <div className={s.regionSelect}>
        <Radio.Group onChange={onChange} value={value}>
          <Radio.Button value="with">With</Radio.Button>
          <Radio.Button value={null}>All</Radio.Button>
          <Radio.Button value="without">Without</Radio.Button>
        </Radio.Group>
      </div>
    </>
  );
}

export default WithAllWithoutGroupButton;
