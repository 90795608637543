//@ts-nocheck
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Drawer, Form, Button, Input, message, Row, Col } from 'antd';
import { PlusCircleTwoTone } from '@ant-design/icons';
import s from './styles.module.scss'
import useBoolean from '../../../Utils/useBoolean';
import { groupMappingCreate, groupMappingDelete } from '../../../reducers/dashboard';
import useForm from 'antd/lib/form/hooks/useForm';
import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined';
import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';
import CloseOutlined from '@ant-design/icons/lib/icons/CloseOutlined';

interface CreateMapping {
  group: any,
  afterUpdate: any,
  name: string,
  region: string,
  productId: string,
  groupId?: string
}


const CreateMapping: React.FC<CreateMapping> = ({ group, afterUpdate, name, region, productId, groupId }) => {

  const [items, setItems] = useState([{ pltId: 2, productId: "", region: region }]);

  const dispatch = useDispatch();

  const [isProcessing, setIsProcessing] = useState(false);

  const [form] = useForm()
  const { getFieldValue } = form;

  const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);


  const onSubmit = useCallback(
    () => {

      let formData

      if (!group) {
        formData = {
          productId,
          region,
          items
       }
      }
      if (group) {
        formData = {
          productId,
          region,
          items: [...items, ...group.map((item) => ({ pltId: 2, productId: item.productId, region: region }))]
       }
      }
      
          dispatch(groupMappingCreate(formData))
          .then((res) => {
            !res.error && message.success('Mapping has been created!');
            form.resetFields()
            close()
            setIsProcessing(false)
          })
          .catch((e) => {
            message.error(`Failed to create mapping: ${e}`)
            setIsProcessing(false)
          })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items],
  );

  const onAddItem = () => {
    setItems([...items, { pltId: 2, productId: "", region: region }]);
  };

  const onRemoveItem = (index) => {
    const filteredItems = items.filter((item, i) => i !== index);
    setItems(filteredItems);
  };

  const onUpdateItem = (index, key, value) => {
    const updatedItems = [...items];
    updatedItems[index][key] = value;
    setItems(updatedItems);
  };

  const handleDeleteMappings = useCallback(() => {
    dispatch(groupMappingDelete({productId, region}))
    .then((res) => {
      !res.error && message.success('Mapping has been deleted!');
      form.resetFields()
      close()
      setIsProcessing(false)
    })
    .catch((e) => {
      message.error(`Failed to delete mapping: ${e}`)
      setIsProcessing(false)
    })
  }, [])


  return (
    <div>
      <Drawer open={isOpen} title='Create new Product Mapping' width={1000} closable placement="right" onClose={close}>
       
        
        <Form form={form} layout="vertical" className={s.addMaping} onFinish={onSubmit}>
        <Row gutter={10} style={{marginBottom: '10px'}}>
          <Col span={16}>
            <Input disabled value={name} addonBefore="Name"  style={{marginBottom: '10px'}}/>
            <Input disabled value={region && region.toUpperCase()} addonBefore="Region"  />
            </Col>
          </Row>

        {group?.map((item, index) => (
          <div key={index}>
          <Row  gutter={10}>
          <Col span={16}>
            <Form.Item name="productId" label="Other IDs">
              <Input value={item?.productId} disabled placeholder={item?.productId}/>
            </Form.Item>
            </Col>
          </Row>
          </div>
          ))}


          <Row  gutter={10} style={{marginBottom: '10px'}}>
          <Col span={16}>
            
              {items.map((item, index) => (
                <div className={s.mappingInputs} key={index}>
                  <Form.Item label={`ID ${index + 1}`} name={`items[${index}].productId`}>
                    <Input onChange={(e) => onUpdateItem(index, "productId", e.target.value)} addonAfter={<CloseOutlined onClick={() => onRemoveItem(index)}/>}/>
                  </Form.Item>
                </div>
              ))}
              <Button type="dashed" onClick={onAddItem} style={{ width: "100%" }}>
                Add ID
              </Button>
            
        </Col>
        </Row>

        <Row  gutter={10}>
          <Form.Item style={{marginRight: '15px'}}>
          {isProcessing ? 
            <Button
                  type="primary"
                  htmlType="submit"
                  disabled
              >
                  <LoadingOutlined />
              </Button>
             : 
              <Button
                  type="primary"
                  htmlType="submit"
              >
                  Submit
              </Button>
            }
          </Form.Item>

          <Button
                  disabled={!group}
                  type="primary"
                  onClick={handleDeleteMappings}
                  icon={<DeleteOutlined />}
                  danger
              >
                  Delete Existing Mappings
              </Button>
              </Row>
        </Form>
      </Drawer>
      <Button type="text" shape="circle" onClick={open}>
            
          <PlusCircleTwoTone style={{width: '100%'}} className="ResellTable__add" />
          
      </Button>
    </div>
  );
};

export default React.memo(CreateMapping);
