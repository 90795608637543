import React from 'react';
import { Typography } from 'antd';
import s from './styles.module.scss';
import Settings from './Settings';

const { Title } = Typography;

function DashboardSettings() {
  return (
    <div className={s.dashboardSettings}>
      <Title style={{marginLeft: '24px'}}>Dashboard Settings</Title>
      <div className={s.layout}>
        <Settings />
      </div>
    </div>
  );
}

export default DashboardSettings;
