//@ts-nocheck
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import createStore from './boots/store';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './boots/App';
import TokenService from './services/TokenService';
import { authSetIsAuthenticated } from './reducers/auth';


const store = createStore({});

if (TokenService.hasToken()) {
  //@ts-ignore
  store.dispatch(authSetIsAuthenticated(true));
}

TokenService.subscribe(function(isAuthenticated: boolean) {
  store.dispatch(authSetIsAuthenticated(isAuthenticated));
});

const AppRouter = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  );
};

//@ts-ignore
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<AppRouter/>);
