//@ts-nocheck
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useBoolean from '../../Utils/useBoolean';
import { Drawer, Form, Button, Input, message, Radio, Row, Col, Switch, Card, Checkbox, Tag, Typography } from 'antd';
import { PlusCircleTwoTone } from '@ant-design/icons';
// import { addMapping } from '../../Reducers/resell';
import s from './styles.module.scss'
import ProductCreateDigiseller from './CreateForms/Digiseller';
import XboxLookup from './CreateForms/XboxLookup';
import RadioRegionsXbox from '../../components/RadioRegionsXbox';
import ProfitInput from './ProfitInput';
import PrintPrice from '../../components/Print/PrintPrice';
import useForm from 'antd/lib/form/hooks/useForm';
import { createProductMapping, lookupGames } from '../../reducers/dashboard';
import ProductCreateGamivo from './CreateForms/Gamivo';
import ProductCreateG2A from './CreateForms/G2A';
import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined';
import GamesLookup from './CreateForms/GameLookup';
import ProductCreatePlatiMarket from './CreateForms/PlatiMarket';
import ProductCreateSearch from './CreateForms/Kinguin';
import { Regions, RegionsXbox } from '../../types/rootState';

const { Title } = Typography

interface CreateProductForm {
  record?: any,
  afterUpdate?: any,
  list: any,
}

interface RegToCode {
  EUROPE: string,
  NORTH_AMERICA: string,
  GLOBAL: string,
  RUSSIA: string,
  CIS: string,
}

interface PrintPrice {
  price: number
  discount: number
}

interface XboxRegPrices {
  p: number,
  dp: number,
  d: number,
  gld: boolean,
  ea: boolean,
  gamepass: boolean,
  eod: number,
  srcDp: number,
  acf: number,
  pt: number,
  cn: number
}

interface XboxReg {
  region: string
  base_curr: string
  base_dp_eur: number
  base_p_eur: number
  base_d: number
  best_curr: string
  best_dp_eur: number
  best_p_eur: number
  best_d: number
  best_pct: number
  gld: boolean
  ea: boolean
  gamepass: boolean
  eod: number
  cn: number
}

interface XboxGameSearch {
  app_id: string
  app_name:string
  app_type: string
  discount: number
  gld: boolean
  ea: boolean
  gamepass: boolean
  eod: number
  price_discount: number
  price_initial: number
}

const PrintPriceData: React.FC<PrintPrice> = ({ price, discount }) => {
  if (!price) {
    return <>-</>;
  }

  return (
    <>
      <small>
        <b>EUR</b>
      </small>
      &nbsp;
      <PrintPrice price={price} />
      {discount && (
        <>
          &nbsp;(-&nbsp;{discount}
          %)
        </>
      )}
    </>
  );
}

const CreateProduct: React.FC<CreateProductForm> = ({
                      record,
                      afterUpdate,
                      list,
                      
                   }) => {

                    const regionsToCode: RegToCode = {
                      EUROPE: 'eu',
                      NORTH_AMERICA: 'na',
                      GLOBAL: 'glo',
                      RUSSIA: 'ru',
                      CIS: 'cis',
                    };
  
  const dispatch = useDispatch();

  const [form] = useForm()
  const { getFieldValue } = form;

  const [isProcessing, setIsProcessing] = useState(false)
  const [market, setMarket] = useState(3);
  const [xboxSelected, setXboxSelected] = useState<XboxGameSearch>();
  const [productName, setProductName] = useState('')
  const [mktProductId, setMktProductId] = useState<number | null>(null);
  const [parentCategoryId, setParentCategoryId] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [isSub, setIsSub] = useState<boolean>();
  const [region, setRegion] = useState('');
  const [enabled, setEnabled] = useState(true);
  const [active, setActive] = useState(false);
  const [overwriteProfit, setOverwriteProfit] = useState(false);
  const [profitValue, setProfitValue] = useState<number>(null);
  const [minProfitValue, setMinProfitValue] = useState<number>(null);
  const [selectRegionError, setSelectRegionError] = useState('');
  const [xboxPrice, setXboxPrice] = useState(null);
           
  // console.log('xboxSelected', xboxSelected)
  // console.log('parentCategoryId', parentCategoryId)
  // console.log('categoryId', categoryId)
  // console.log('mktProductId', mktProductId)
  // console.log('isSub', isSub)
  

  const options = [
    { label: 'GMVO', value: 3 },
    { label: 'G2A', value: 4 },
    { label: 'GGSEL', value: 5 },
    { label: 'Plati', value: 6 },
    { label: 'Kinguin', value: 7 },
  ];

  const onChange = (e: any) => {
    e.preventDefault()
    setMarket(e.target.value)
  }

  // console.log('MARKET', market)

  const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);

  const handleSetActive = () => {
    setActive((prev) => !prev)
  }

  const handleSetEnabled = () => {
    setEnabled((prev) => !prev)
  }

  const handleChangeProfit = useCallback(
    (value: number) => {
      setProfitValue(value)
    },
    [profitValue],
  );

  const handleChangeProfitMin = useCallback(
    (value: number) => {
      setMinProfitValue(value)
    },
    [minProfitValue],
  );

  const onSubmit = useCallback(
    () => {
      setIsProcessing(true)
        let formData
        
        if(market === 5 || market === 6) {
          formData = {
            mktId: market,
            mktProductId: (mktProductId as number).toString(), // handle After
            // pltId: in reducer service as 2
            pltProductId: (xboxSelected as XboxGameSearch).app_id,
            pltRegion: getFieldValue('region'),
            isActive: active,
            enabled: enabled,
            // profitMin: minProfitValue,
            // profitMax: profitValue,
            setDefault: [
              "profit"
            ],
              ext: {
              "@class": "es.ibs.tech.dashboard.digiseller.DigiProductMappingExt",
              categoryId: categoryId,
              name: (xboxSelected as XboxGameSearch).app_name,
              parentCategoryId: parentCategoryId,
              isSub: isSub
              }
              }
            } else {
              formData = {
                mktId: market,
                mktProductId: (mktProductId as number).toString(), // handle After
                // pltId: in reducer service as 2
                pltProductId: (xboxSelected as XboxGameSearch).app_id,
                pltRegion: getFieldValue('region'),
                isActive: active,
                enabled: enabled,
                // profitMin: minProfitValue,
                // profitMax: profitValue,
                setDefault: [
                  "profit"
                ],
                  }
            } 
          
          if (overwriteProfit) {
            if (profitValue === null) {
              message.error('Profit and / or ProfitMin must be filled in')
            }
            if (minProfitValue === null) {
              message.error('Profit and / or ProfitMin must be filled in')
              }
            

            if ((profitValue === null && minProfitValue === null) && (profitValue || minProfitValue)) {
              if (profitValue) {
                formData.profitMax = +profitValue / 100;
              } else {
                formData.profitMin = +minProfitValue / 100;
              }
            } else {
              if (+minProfitValue > +profitValue) {
                  // setProfitValue(profitValue)
                  message.error('Profit must be greater than ProfitMin')
                  // setMinProfitValue(minProfitValue)
                  // message.error('ProfitMin must be less than Profit')
                  return;
                  }

              formData.profitMin = +minProfitValue / 100;
              formData.profitMax = +profitValue / 100;
              
            }
          }

          // console.log('FORMDATA', formData)
          dispatch(createProductMapping(formData))
          //@ts-ignore
          .then((res) => {
            !res.error && message.success('Product has been created!');
            form.resetFields()
            afterUpdate()
            close()
            setIsProcessing(false)
          })
          .catch((e: string) => {
            message.error(`Failed to create product: ${e}`)
            setIsProcessing(false)
          })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [xboxSelected, overwriteProfit, minProfitValue, profitValue],
  );

  // console.log('DATA', productName, mktProductId, market, xboxSelected, region, enabled, active, profitValue, minProfitValue)
  // console.log('XBOXSELECTED', xboxSelected)

  return (
    <>
      <Drawer visible={isOpen} title="Create New Product" width={700} closable placement="right" onClose={close}>
        <Form form={form} layout="vertical" className={s.addMaping} onFinish={onSubmit}>
        <Row gutter={10}>
          <Col span={16}>
            <Radio.Group
            style={{marginBottom: '12px'}}
            options={options}
            onChange={onChange}
            value={market}
            optionType="button"
            defaultValue={market}
            />
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={16}>
            {/* <Title>Games Search</Title> */}
            <Form.Item name="market_lookup">
              {market === 6 ?
              <ProductCreatePlatiMarket setCategoryId={setCategoryId} setParentCategoryId={setParentCategoryId} setMktProductId={setMktProductId} setIsSub={setIsSub} mktProductId={mktProductId} />
              : market === 5 ? 
              <ProductCreateDigiseller setCategoryId={setCategoryId} setParentCategoryId={setParentCategoryId} setMktProductId={setMktProductId} />
             : market === 3 ?
              <ProductCreateGamivo setMktProductId={setMktProductId}/>
              : market === 4 ?
               <ProductCreateG2A setMktProductId={setMktProductId}/>
               :
               <ProductCreateSearch setMktProductId={setMktProductId}/>
              }
            </Form.Item>
            </Col>
          </Row>

          {/* <Row gutter={10}>
          <Col span={16}>
            <Form.Item name="test">
              <GamesLookup />
            </Form.Item>
            </Col>
          </Row> */}

          <Row gutter={10}>
          <Col span={16}>
            <Form.Item name="region">
              <RadioRegionsXbox setRegion={setRegion} />
            </Form.Item>
            </Col>
          </Row>

          <Row gutter={10}>
          <Col span={16}>
            <Form.Item label="XBOX Search" name="xbox_lookup">
              <XboxLookup getFieldValue={getFieldValue} onSelect={setXboxSelected} />
            </Form.Item>
            </Col>
          </Row>

          {market !== 3 && market !== 4 && market !== 7 &&
          <Row gutter={10}>
          <Col span={16}>
            <Form.Item name="ggsel_name">
            {xboxSelected && xboxSelected.app_name?.length > 55 &&  <span style={{color: 'red'}}>Name should be no more than 55 symbols long</span> }
              <Input value={xboxSelected?.app_name} onChange={(e) => setXboxSelected({...xboxSelected, app_name: e.target.value})}/>
            </Form.Item>
            </Col>
          </Row>
           }

          <Row gutter={5}>
          <Col span={5}>
            <Form.Item name="active" label={active ? 'Active' : 'Non-Active'}>
              <Switch onChange={handleSetActive}/>
            </Form.Item>
            <Form.Item name="enabled" label={enabled ? 'Enabled' : 'Not Enabled'}>
              <Switch onChange={handleSetEnabled} defaultChecked/>
            </Form.Item>
            </Col>
          </Row>


          <Row gutter={10} className={s.mbSmall}>
          <Col span={16}>
          <Card size="small" style={{ minHeight: '180px', marginBottom: '12px' }} >
          
            <Form.Item
            name="overwrite_profit"
            className={s.G2AProductCreateForm__formItem}
              label={
                <>
                  <Checkbox
                    className={s.fr}
                    onChange={(e) => {
                      if (!e.target.checked) {
                        setMinProfitValue(null)
                      }
                      setOverwriteProfit(e.target.checked);
                    }}
                    checked={overwriteProfit}
                    disabled={!xboxSelected}
                  >
                    Overwrite
                  </Checkbox>
                  Profit Min
                </>
              }
              >
          <Row gutter={16} align="middle">
          <Col span={24}>
              <ProfitInput overwriteProfit={overwriteProfit} handleChangeProfit={handleChangeProfitMin} />
              </Col>
              </Row>
        </Form.Item>
        <Form.Item  name="profit" className={s.G2AProductCreateForm__formItem} label="Profit">
            <Row gutter={16} align="middle">
                <Col span={24}>
              <ProfitInput overwriteProfit={overwriteProfit} handleChangeProfit={handleChangeProfit} />
              </Col>
              </Row>
        </Form.Item>

            {xboxSelected && (
              <Row className="mb-small">
                {xboxSelected.gamepass && <Tag color="green">Game Pass</Tag>}
                {xboxSelected.ea && (
                  <Tag color="blue" className="ml">
                    EA
                  </Tag>
                )}
                {xboxSelected.gld && (
                  <Tag color="yellow" className="ml">
                    Gold
                  </Tag>
                )}
              </Row>
            )}
            <Row className="mb-small">
              Price Initial:&nbsp;
              <PrintPriceData
                price={((xboxSelected && xboxSelected.price_initial) as number)}
                discount={((xboxSelected && xboxSelected.discount) as number)}
              />
            </Row>
            <Row>
              Price Discount:&nbsp;
              <PrintPriceData price={((xboxSelected && xboxSelected.price_discount) as number)} discount={0} />
            </Row>
          </Card>
            </Col>
          </Row>
          <Form.Item>
          {isProcessing ? 
            <Button
                  type="primary"
                  htmlType="submit"
                  disabled
              >
                  <LoadingOutlined />
              </Button>
             : 
              <Button
                  type="primary"
                  htmlType="submit"
              >
                  Submit
              </Button>
            }
          </Form.Item>
        </Form>
      </Drawer>
      <Button onClick={open}>
          <PlusCircleTwoTone className="ResellTable__add" />
          Create New Product
      </Button>
    </>
  );
};

export default React.memo(CreateProduct);
