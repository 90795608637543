import React from 'react';
import PrintPriceRatio from '../Print/PrintPriceRatio';

interface MinPriceRatio {
  priceMin?: number,
  priceMax?: number,
  className?: string,
  isDefault?: boolean
};


const MinPriceRatio: React.FC<MinPriceRatio> = ({ priceMin, priceMax, className, isDefault }) => {
  return (
    <span className={className}>
      <PrintPriceRatio isDefault={isDefault} price={priceMin} />
      <span className="ml-small mr-small">/</span>
      <PrintPriceRatio isDefault={isDefault} price={priceMax} />
    </span>
  );
}

export default MinPriceRatio;
