import { Col, Row, Tag } from 'antd';
import React, { useMemo } from 'react';
import styles from './styles.module.scss';

interface StatTag {
  profit?: number;
  profitPct?: number;
  sold?: number;
  profitAvg?: number;
}

const StatisticsTag: React.FC<StatTag> = ({ profit, profitPct, sold, profitAvg }) => {
  const formattedProfitAvg = useMemo(() => profitAvg || 'n/d', [profitAvg]);
  const formattedProfitPct = useMemo(() => (profitPct ? `${profitPct}%` : 'n/d'), [profitPct]);
  const formattedProfit = useMemo(() => (profit ? profit.toFixed(2) : 'n/d'), [profit]);
  const formattedSold = useMemo(() => sold || 'n/d', [sold]);

  return (
    <Row style={{ padding: '24px 0' }} gutter={[2, 4]}>
     
        <Col span={24}>
          <Tag color="default" className="stat-tag">
            <small>{formattedProfitAvg}</small>
          </Tag>
        </Col>
        <Col span={24}>
          <Tag color={profitPct as number < 15 ? (profitPct as number < 10 ? 'red' : 'gold') : 'default'} className="stat-tag">
            <small>{formattedProfitPct}</small>
          </Tag>
        </Col>
        <Col span={24}>
          <Tag color={profit ? 'blue' : 'default'} className="stat-tag">
            <small>{formattedProfit}</small>
          </Tag>
        </Col> <Col span={24}>
        <Tag color={sold ? 'cyan' : 'default'} className="stat-tag">
          <small>{formattedSold}</small>
        </Tag>
      </Col>
    </Row>
  );
};

export default React.memo(StatisticsTag);
