import { createAction, createReducer } from '@reduxjs/toolkit';
import frc from 'front-end-common';
import AutoMapperService from '../services/AutoMappingService';

const { getDefaultHandler, getDefaultState } = frc.utils.reducerTools;


export const autoMapperListFetch = createAction('getList', (params) => ({
  payload: AutoMapperService.fetchList(params)
}))

export const declineMapping = createAction('declineMapper', (data) => ({
  payload: AutoMapperService.decline(data)
}))

export const approveMapping = createAction('approveMapper', (data) => ({
  payload: AutoMapperService.approve(data)
}))

const initState = {
  getList: getDefaultState(),
  declineMapper: getDefaultState(),
  approveMapper: getDefaultState(),
};

export const AutoMapperReducer = createReducer(initState, {
  ...getDefaultHandler(autoMapperListFetch, 'getList'),
  ...getDefaultHandler(declineMapping, 'declineMapper'),
  ...getDefaultHandler(approveMapping, 'approveMapper'),
});

export default AutoMapperReducer

