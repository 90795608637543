import { Checkbox, Col, Input, InputNumber, Modal, Row, Slider } from 'antd';
import React from 'react';
import styles from './styles.module.scss';
import cx from '../../../Utils/cx';
import StatisticsSwitcher from '../../StatisticsSwitcher';
import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined';
import SettingOutlined from '@ant-design/icons/lib/icons/SettingOutlined';

interface ConfigModal {
  visible: boolean,
  close: ((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined,
  onOk: ((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined,
  setStatisticSorter: any,
  statisticsSorter: string,
  isConfigProcessing: boolean
}

const ConfigModal: React.FC<ConfigModal> = ({ isConfigProcessing, visible, close, onOk, statisticsSorter, setStatisticSorter }) => {
  return (
    <Modal
    open={visible}
    onCancel={close}
    onOk={onOk}
    title={<div><SettingOutlined /> Config</div>}
    destroyOnClose
    maskClosable
    closable={false}
    okButtonProps={{
      disabled: isConfigProcessing,
      icon: isConfigProcessing ? <LoadingOutlined /> : ''
    }}
    >
      Sort statistics by <StatisticsSwitcher statisticsSorter={statisticsSorter} setStatisticSorter={setStatisticSorter} />
    </Modal>
  )
}

export default ConfigModal
