import { createAction, createReducer } from '@reduxjs/toolkit';
import frc from 'front-end-common';
import FuseService from '../services/FuseService';

const { getDefaultHandler, getDefaultState } = frc.utils.reducerTools;


export const fuseSettingsFetch = createAction('settingsFetchFuse', (data) => ({
  payload: FuseService.fetchSettings(data)
}))

export const fuseSettingsFetchReset = createAction('settingsFetchFuseReset')

export const fuseSettingsSchema = createAction('fuseSettingsSchemaFetch', (data) => ({
  payload: FuseService.fetchSettingsSchema(data)
}))

export const fuseSettingsUISchema = createAction('fuseSettingsUiSchemaFetch', (data) => ({
  payload: FuseService.fetchSettingsUISchema(data)
}))

export const fuseSettingsUpdate = createAction('fuseSettingsUpdate', (data) => ({
  payload: FuseService.updateSettings(data)
}))

export const fuseSettingsUpdateReset = createAction('fuseSettingsUpdateReset')

const initState = {
  settingsFetchFuse: getDefaultState(),
  settingsFetchFuseReset: getDefaultState(),
  fuseSettingsSchemaFetch: getDefaultState(),
  fuseSettingsUiSchemaFetch: getDefaultState(),
  fuseSettingsUpdate: getDefaultState(),
  fuseSettingsUpdateReset: getDefaultState(),
};

export const FuseReducer = createReducer(initState, {
  ...getDefaultHandler(fuseSettingsFetch, 'settingsFetchFuse'),
  ...getDefaultHandler(fuseSettingsFetchReset, 'settingsFetchFuseReset'),
  ...getDefaultHandler(fuseSettingsSchema, 'fuseSettingsSchemaFetch'),
  ...getDefaultHandler(fuseSettingsUISchema, 'fuseSettingsUiSchemaFetch'),
  ...getDefaultHandler(fuseSettingsUpdate, 'fuseSettingsUpdate'),
  ...getDefaultHandler(fuseSettingsUpdateReset, 'fuseSettingsUpdateReset'),
});

export default FuseReducer

