import React from 'react';
import { Navigate } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import MainLayout from '../../MainLayout';

function RedirectGuestToSignInPage() {
  //@ts-ignore
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated, shallowEqual);
  
  return isAuthenticated ? <MainLayout /> : <Navigate to={`/sign-in`} />;
}

export default RedirectGuestToSignInPage;
