import { message } from 'antd';

export default function ValidateMinCurrentProfit(profitMin, newProfitMin, profit, newProfit) {
  if (!newProfitMin && newProfitMin !== profitMin) {
    message.error('You can not reset only min profit.');
    return false;
  }
  if (newProfitMin < -50) {
    message.error('Min Profit must be more than -50%');
    return false;
  }

  if (!newProfit && newProfit !== profit) {
    message.error('You can not reset only profit.');
    return false;
  }

  if (newProfit < -50) {
    message.error('Profit must be more than -50%');
    return false;
  }

  if (newProfit && newProfitMin && newProfit < newProfitMin) {
    message.error('Profit must be more than -50%');
    return false;
  }
  return true;
}
