import { createAction, createReducer } from '@reduxjs/toolkit';
import frc from 'front-end-common';
import DashboardService from '../services/DashboardService';

const { getDefaultHandler, getDefaultState } = frc.utils.reducerTools;

// export const resellListFetchReset = createAction('listReset')

export const listFetch = createAction('list', (params) => ({
  payload: DashboardService.fetchOrderedList(params),
}))

export const MarketsFetch = createAction('markets', () => ({
  payload: DashboardService.fetchMarketsList(),
}))

export const commentsFetch = createAction('comments', (params) => ({
  payload: DashboardService.fetchComments(params),
}))

export const addNewComment = createAction('commentsAdd', (params, comment) => ({
  payload: DashboardService.addComment(params, comment),
}))

export const changeAction = createAction('changeAction', (param) => ({
  payload: DashboardService.setAction(param),
}));

export const updateProfit = createAction('updateProfit', (param) => ({
  payload: DashboardService.xboxUpdateProfit(param),
}));

export const profitOverwrite = createAction('profitOverwrite', (param) => ({
  payload: DashboardService.profitOverwriteToggle(param),
}));

export const fetchProductsLookup = createAction('lookupList', () => ({
  payload: DashboardService.fetchProducts(),
}));

export const lookupDigisel = createAction('lookDigi', (q) => ({
  payload: DashboardService.digiLookup(q),
}));

export const lookupPlatiMarket = createAction('lookPlatiMkt', (q) => ({
  payload: DashboardService.platiMarketLookup(q),
}));

export const lookupG2A = createAction('lookG2A', (q) => ({
  payload: DashboardService.g2aLookup(q),
}));

export const lookupGamivo = createAction('lookGmvo', (q) => ({
  payload: DashboardService.gamivoLookup(q),
}));

export const lookupKinguin = createAction('lookKinguin', (q) => ({
  payload: DashboardService.kinguinLookup(q),
}));

export const lookupGames = createAction('lookGames', (q) => ({
  payload: DashboardService.gameLookup(q),
}));

export const lookupXbox = createAction('lookXbox', (q, region) => ({
  payload: DashboardService.xboxLookup(q, region),
}));

// export const addProduct = createAction('productCreate', (param) => ({
//   payload: DashboardService.createProduct(param),
// }));

export const fetchRegions = createAction('regionsXbox', (param) => ({
  payload: DashboardService.fetchRegionsXbox(param),
}));

export const createProductMapping = createAction('productCreate', (data) => ({
  payload: DashboardService.createProduct(data)
}))

export const productListSettingsFetch = createAction('settingsFetch', (data) => ({
  payload: DashboardService.fetchSettings(data)
}))

export const productListSettingsFetchReset = createAction('settingsFetchReset')

export const productListSettingsSchema = createAction('settingsSchemaFetch', (data) => ({
  payload: DashboardService.fetchSettingsSchema(data)
}))

export const productListSettingsUISchema = createAction('settingsUiSchemaFetch', (data) => ({
  payload: DashboardService.fetchSettingsUISchema(data)
}))

export const productListSettingsUpdate = createAction('settingsUpdate', (data) => ({
  payload: DashboardService.updateSettings(data)
}))

export const productListSettingsUpdateReset = createAction('settingsUpdateReset')

export const statusFetch = createAction('status', () => ({
  payload: DashboardService.fetchStatus(),
}))

export const groupUpdateProducts = createAction('changeProducts', (data) => ({
  payload: DashboardService.groupUpdate(data),
}))

export const groupUpdateStatus = createAction('changeStatusGroup', (data) => ({
  payload: DashboardService.applyStatusToProducts(data),
}))

export const groupUpdateProfit = createAction('changeProfitGroup', (data) => ({
  payload: DashboardService.changeProfit(data),
}))

export const groupSetDefaultProfit = createAction('changeDefaultProfitGroup', (data) => ({
  payload: DashboardService.setDefaultProfit(data),
}))

export const lockUnlock = createAction('changeStatusLock', (data) => ({
  payload: DashboardService.setLockProduct(data),
}))

export const groupMappingCreate = createAction('addMapping', (data) => ({
  payload: DashboardService.postGroupMapping(data),
}))

export const groupMappingDelete = createAction('deleteMapping', (data) => ({
  payload: DashboardService.deleteGroupMapping(data),
}))

export const mappingDelete = createAction('deleteMappingSolo', (data) => ({
  payload: DashboardService.deleteMapping(data),
}))

export const updatePltProductId = createAction('platformProductIdUpdate', (data) => ({
  payload: DashboardService.updatePlatformProductId(data),
}))

export const getQtyOnStock = createAction('qtyStock', (productId, region) => ({
  payload: DashboardService.getQuantityOnStock(productId, region),
}))

export const orderGames = createAction('order', (pltId, ids, count, maxPrice, orderID, immediate) => ({
  payload: DashboardService.orderGames(pltId, ids, count, maxPrice, orderID, immediate),
}))

export const uploadKeys = createAction('loadKeys', (data) => ({
  payload: DashboardService.keysUpload(data),
}));




const initState = {
  // listFetchReset: ''
  productsList: getDefaultState(),
  markets: getDefaultState(),
  comments: getDefaultState(),
  commentsAdd: getDefaultState(),
  changeAction: getDefaultState(),
  updateProfit: getDefaultState(),
  profitOverwrite: getDefaultState(),
  lookupList: getDefaultState(),
  changeProfitRatios: getDefaultState(),
  resetProfitRatios: getDefaultState(),
  lookDigi: getDefaultState(),
  lookPlatiMkt: getDefaultState(),
  lookG2A: getDefaultState(),
  lookGmvo: getDefaultState(),
  lookKinguin: getDefaultState(),
  lookGames: getDefaultState(),
  lookXbox: getDefaultState(),
  // productCreate: getDefaultState(),
  regionsXbox: getDefaultState(),
  productCreate: getDefaultState(),
  settingsFetch: getDefaultState(),
  settingsFetchReset: getDefaultState(),
  settingsSchemaFetch: getDefaultState(),
  settingsUiSchemaFetch: getDefaultState(),
  settingsUpdate: getDefaultState(),
  settingsUpdateReset: getDefaultState(),
  status: getDefaultState(),
  changeProducts: getDefaultState(),
  changeStatusGroup: getDefaultState(),
  changeProfitGroup: getDefaultState(),
  changeDefaultProfitGroup: getDefaultState(),
  changeStatusLock: getDefaultState(),
  addMapping: getDefaultState(),
  deleteMapping: getDefaultState(),
  deleteMappingSolo: getDefaultState(),
  platformProductIdUpdate: getDefaultState(),
  qtyStock: getDefaultState(),
  order: getDefaultState(),
  loadKeys: getDefaultState()
};

export const DashboardReducer = createReducer(initState, {
  ...getDefaultHandler(listFetch, 'productsList'),
  ...getDefaultHandler(MarketsFetch, 'markets'),
  ...getDefaultHandler(commentsFetch, 'comments'),
  ...getDefaultHandler(addNewComment, 'commentsAdd'),
  ...getDefaultHandler(changeAction, 'changeAction'),
  ...getDefaultHandler(updateProfit, 'updateProfit'),
  ...getDefaultHandler(profitOverwrite, 'profitOverwrite'),
  ...getDefaultHandler(fetchProductsLookup, 'lookupList'),
  ...getDefaultHandler(lookupDigisel, 'lookDigi'),
  ...getDefaultHandler(lookupPlatiMarket, 'lookPlatiMkt'),
  ...getDefaultHandler(lookupG2A, 'lookG2A'),
  ...getDefaultHandler(lookupGamivo, 'lookGmvo'),
  ...getDefaultHandler(lookupKinguin, 'lookKinguin'),
  ...getDefaultHandler(lookupGames, 'lookGames'),
  ...getDefaultHandler(lookupXbox, 'lookXbox'),
  // ...getDefaultHandler(addProduct, 'productCreate'),
  ...getDefaultHandler(fetchRegions, 'regionsXbox'),
  ...getDefaultHandler(createProductMapping, 'productCreate'),
  ...getDefaultHandler(productListSettingsFetch, 'settingsFetch'),
  ...getDefaultHandler(productListSettingsFetchReset, 'settingsFetchReset'),
  ...getDefaultHandler(productListSettingsSchema, 'settingsSchemaFetch'),
  ...getDefaultHandler(productListSettingsUISchema, 'settingsUiSchemaFetch'),
  ...getDefaultHandler(productListSettingsUpdate, 'settingsUpdate'),
  ...getDefaultHandler(productListSettingsUpdateReset, 'settingsUpdateReset'),
  ...getDefaultHandler(statusFetch, 'status'),
  ...getDefaultHandler(groupUpdateProducts, 'changeProducts'),
  ...getDefaultHandler(groupUpdateStatus, 'changeStatusGroup'),
  ...getDefaultHandler(groupUpdateProfit, 'changeProfitGroup'),
  ...getDefaultHandler(groupSetDefaultProfit, 'changeDefaultProfitGroup'),
  ...getDefaultHandler(lockUnlock, 'changeStatusLock'),
  ...getDefaultHandler(groupMappingCreate, 'addMapping'),
  ...getDefaultHandler(groupMappingDelete, 'deleteMapping'),
  ...getDefaultHandler(mappingDelete, 'deleteMappingSolo'),
  ...getDefaultHandler(updatePltProductId, 'platformProductIdUpdate'),
  ...getDefaultHandler(getQtyOnStock, 'qtyStock'),
  ...getDefaultHandler(orderGames, 'order'),
  ...getDefaultHandler(uploadKeys, 'loadKeys'),
});

export default DashboardReducer

