//@ts-nocheck
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Divider, Form, Row } from 'antd';
import LoadingBlock from '../LoadingBlock';
import CurrentProfitRange from './Filters/CurrentProfitRange';
import {
  formDataToUrlData,
  MAX_CURRENT_XBOX_PRICE,
  MAX_PROFIT,
  MIN_CURRENT_XBOX_PRICE,
  MIN_PROFIT,
  urlFiltersToFormData,
} from '../../Utils/filtersControls';
import useForm from 'antd/lib/form/hooks/useForm';
import ProductStatusGroupButton from './Filters/ProductStatusGroupButton';
import XBOXPriceRange from './Filters/XBOXPriceRange';
import LockGroupButton from './Filters/LockGroupButton';
import DiscountDatePicker from './Filters/DiscountDatePicker';
import { UndoOutlined } from '@ant-design/icons';
import SelectRegion from './Filters/SelectRegion';
import SelectMarket from './Filters/SelectMarket';
import SearchNameOrId from './Filters/SearchNameOrId';
import CustomDefaultProfitButton from './Filters/CustomDefaultProfitButton';
import WithAllWithoutGroupButton from './Filters/WithAllWithoutGroupButton';
import { ErrorTwoTone, Inventory2, InventoryTwoTone, LocalOfferTwoTone, PeopleAltTwoTone } from '@mui/icons-material';
import s from './styles.module.scss'
import WithAllWithoutGroupButtonSubscr from './Filters/WithAllWithoutGroupButtonSubscr';
import CommonRadioGroup from './Filters/CommonRadioGroup';


interface defaultFormValues {
    query: string,
    xboxPrice: number[],
    currentProfit: number[],
    withoutCompetitors: string | null,
    discountDate: null[],
    errors: string | null,
    discount: string | null,
    stock: string | null,
    status: string | null,
    active: string | null,
    pltRegion: never[],
    enabled: string | null,
    mktId: never[],
    profitMax: string | null,
    discountSubscr: string | null,
}

interface FilterBlock {
  filters: defaultFormValues,
  onFilter: any,
  query: string,
  isLoading: string,
  onSearch: any
}

function defaultFormValues<defaultFormValues>() {
  return {
    query: '',
    xboxPrice: [MIN_CURRENT_XBOX_PRICE, MAX_CURRENT_XBOX_PRICE],
    currentProfit: [MIN_PROFIT, MAX_PROFIT],
    withoutCompetitors: null,
    discountDate: [null, null],
    errors: null,
    discount: null,
    status: null,
    active: null,
    enabled: null,
    stock: null,
    pltRegion: [],
    mktId: [],
    profitMax: null,
    discountSubscr: null
  };
}

const FiltersBlock: React.FC<FilterBlock> = ({ filters, onFilter, query, isLoading, onSearch, forAutoMapping }) => {
  const [form] = useForm()
  // const { getFieldDecorator } = form;
  const [startUrlFilters, setStartUrlFilters] = useState({});

  const setValuesForm = useCallback(
    (values: defaultFormValues) => {
      form.setFieldsValue(values);
    },
    [form],
  );

  const handleApplyFilters = useCallback((values: any) => {
        
        const { filters: filtersValue, query: newQuery } = formDataToUrlData({ ...values, query: values?.query ? values.query.trim() : null});
        
        onFilter({...filtersValue }, newQuery)
        
  }, [onFilter]);

  const handleResetFilters = useCallback(() => {
    setValuesForm(defaultFormValues());
    //@ts-ignore
    handleApplyFilters();
  }, [form]);

  useEffect(() => {
    // const filters = { ...defaultFormValues() }
    const newFilters = urlFiltersToFormData(filters, query, defaultFormValues());
    setValuesForm(newFilters);
    setStartUrlFilters(newFilters);
    // console.log('NEWFILTERS', newFilters)
  }, [filters, query]); // eslint-disable-line

  // console.log('filters', filters)

  return (
    <LoadingBlock loading={isLoading}>

      <Form onFinish={handleApplyFilters} form={form}>
      <Row style={{marginTop:"20px"}}>
        <Col span={5}>
          <Form.Item
            name='query'
            initialValue={defaultFormValues().query}
            > 
              <SearchNameOrId onSearch={onSearch} />
          </Form.Item>
          </Col>
        </Row>
        {!forAutoMapping &&
        <Row>
         <Col span={4}>
            <Form.Item name='currentProfit' initialValue={defaultFormValues().currentProfit}>
              <CurrentProfitRange min={MIN_PROFIT} max={MAX_PROFIT} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name='xboxPrice' initialValue={defaultFormValues().xboxPrice}>
              <XBOXPriceRange min={MIN_CURRENT_XBOX_PRICE} max={MAX_CURRENT_XBOX_PRICE} />
            </Form.Item>
          </Col>
          <Col span={4} >
            <Form.Item name="discountDate" initialValue={defaultFormValues().discountDate}>
              <DiscountDatePicker />
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item name="profitMax" initialValue={defaultFormValues().profitMax}>
              <CustomDefaultProfitButton className={s.regionSelect} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name='active' initialValue={defaultFormValues().active}>
            <CommonRadioGroup icon={<Inventory2 style={{fontSize: '12px', marginRight: '4px', color: 'rgb(24, 144, 255)'}} />} title="Active" />
            </Form.Item>
          </Col>
          
          <Col span={4} style={{marginBottom: '0px !important'}}>
            <Form.Item name="stock" initialValue={defaultFormValues().stock}>
              <WithAllWithoutGroupButton icon={<InventoryTwoTone style={{fontSize: '12px', marginRight: '4px', color: 'rgb(24, 144, 255)'}} />} title="Stock" />
            </Form.Item>
          </Col>
        </Row>
          }
        <Row>
        {!forAutoMapping &&
        <>
        <Col span={4} >
            <Form.Item name="discount" initialValue={defaultFormValues().discount}>
            <WithAllWithoutGroupButton icon={<LocalOfferTwoTone style={{fontSize: '12px', marginRight: '4px', color: 'rgb(24, 144, 255)'}} />} title="Discount" />
            </Form.Item>
          </Col>
          <Col span={4} >
            <Form.Item name="discountSubscr" initialValue={defaultFormValues().discountSubscr}>
            <WithAllWithoutGroupButtonSubscr icon={<LocalOfferTwoTone style={{fontSize: '12px', marginRight: '4px', color: 'rgb(24, 144, 255)'}} />} title="Discount (with subscr)" />
            </Form.Item>
          </Col>
        <Col span={4}>
            <Form.Item name='errors' initialValue={defaultFormValues().errors}>
            <WithAllWithoutGroupButton icon={<ErrorTwoTone style={{fontSize: '12px', marginRight: '4px', color: 'rgb(24, 144, 255)'}} />} title="Errors" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="status" initialValue={defaultFormValues().status}>
              <ProductStatusGroupButton className={s.regionSelect} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="enabled" initialValue={defaultFormValues().enabled}>
              <LockGroupButton className={s.regionSelect} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name='withoutCompetitors' initialValue={defaultFormValues().withoutCompetitors}>
            <WithAllWithoutGroupButton icon={<PeopleAltTwoTone style={{fontSize: '12px', marginRight: '4px', color: 'rgb(24, 144, 255)'}} />} title="Comptetitors" />
            </Form.Item>
          </Col>
          </>}
          <Col span={4}>
            <Form.Item name="pltRegion" initialValue={defaultFormValues().pltRegion}>
              <SelectRegion />
            </Form.Item>
          </Col>
          
          <Col span={4}>
            <Form.Item name="mktId" initialValue={defaultFormValues().mktId}>
              <SelectMarket />
            </Form.Item>
          </Col>
          </Row>
                <Row>
                <Col span={3}>
                <Form.Item>
                      <Button
                      htmlType='submit'
                      type='primary'
                      > Apply Filters 
                      </Button>
                </Form.Item>
                </Col>
                <Col span={3}>
                <Form.Item>
                        <Button
                        onClick={handleResetFilters}
                        type='primary'
                        icon={<UndoOutlined />}
                        > Reset Filters 
                        </Button>
                </Form.Item>
                </Col>
                </Row>
                <Divider />
      </Form>
    </LoadingBlock>
  );
}

export default FiltersBlock;
