import { Checkbox, Col, Input, InputNumber, Modal, Row, Slider } from 'antd';
import React from 'react';
import OrderKeysButton from '../OrderKeysButton';
import styles from './styles.module.scss';
import cx from '../../../Utils/cx';

interface OrderKeys extends OrderKeysButton {
  visible: boolean,
  close: ((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined,
  onOk: ((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined,
  amount: number,
  price: number,
  number: number,
  setNumber: any,
  setMaxPrice: any,
}

const OrderKeysModal: React.FC<OrderKeys> = ({ visible, close, onOk, pltId, pltProductId, region, pltName, amount, price, number, setNumber, setMaxPrice }) => {


  return (
    <Modal
    open={visible}
    onCancel={close}
    onOk={onOk}
    title={`Order Keys: ${pltName || ''} (${region})`}
    destroyOnClose
    maskClosable
    closable={false}
    okButtonProps={{
      disabled:
          number < 1 ||
          number > 500 ||
          !price,
    }}
    >
      <Row className={styles.mbSmall}>
        <Col span={4} className={styles.flexLeft} style={{ minHeight: '32px' }}>
          <b>Quantity:</b>
        </Col>
        <Col span={15}>
          <Slider
            min={1}
            max={500}
            onChange={setNumber}
            value={typeof number === 'number' ? number : 0}
          />
        </Col>
        <Col span={5}>
          <InputNumber
            min={1}
            max={500}
            className={cx(styles.marginLeft, styles.floatRight)}
            value={number}
            onChange={(v) => {
              let n = Math.round(v as number);
              if (n < 1) n = 1;
              const max = 500;
              if (n > max) n = max;
              setNumber(n);
            }}
          />
        </Col>
      </Row>

      <Row className={styles.mbSmall}>
        <Col span={19} className={styles.flexLeft} style={{ minHeight: '32px' }}>
          <b>Sell price, €:</b>
        </Col>
        <Col span={5} style={{ paddingLeft: 8 }}>
          <InputNumber
            className={styles.floatRight}
            min={0.01}
            value={price}
            precision={2}
            onChange={setMaxPrice}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 15 }} >
        <Col span={19} className={styles.flexLeft} >
          <b>Quantity on stock:</b>
        </Col>
        <Col span={5} style={{ paddingLeft: 8 }} >
          <span style={{ float:"right" }}>{amount}</span>
        </Col>
      </Row>
      
    </Modal>
  )
}

export default OrderKeysModal
