//@ts-nocheck
import { Divider, Form, Input, Row, Spin, Typography } from 'antd';
import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductsLookup, listFetch } from '../../reducers/dashboard';
import ProductsState from '../../types/rootState';
import useBoolean from '../../Utils/useBoolean';
import useTable from '../../Utils/useTable';
import EditIsActive from '../../components/ControlsBlock/EditIsActive';
import EditProfit from '../../components/ControlsBlock/EditProfit';
import ResetProfit from '../../components/ControlsBlock/ResetProfit';
import CreateProduct from './CreateProduct';
import FiltersBlock from '../../components/Filters';
import ProductsTable from './ProductsTable'
import s from './styles.module.scss'
import DashboardConfig from '../../components/DashboardConfig';
import UploadKeys from './UploadKeys';

function Dashboard(props: any) {

     const { list, fetch, filters, query, onSearch, onFilter, pagination, onChange } = useTable((state: any) => state.dashboard.productsList, listFetch)

     const [selectedRow, onSelectChange] = useState<Array<string>>([]);
     const [isConfigProcessing, setIsConfigProcessing] = useState(false);


      const { payload, isLoading } = useSelector((state: ProductsState) => state?.dashboard?.productsList) || {}

      const {
        // list, 
        total 
      } = useMemo(
        () => ({
          // list: (payload && payload.page) || [],
          total: (payload && payload.total) || [],
        }),
        [payload]
      )


    const finalCountRecords = useMemo(() => (selectedRow.length ? selectedRow.length : total), [selectedRow, total]);
  
  
  return (
    <>
    
    <div className={s.buttonsGroup}>
        <DashboardConfig setIsConfigProcessing={setIsConfigProcessing} isConfigProcessing={isConfigProcessing} />
        <FiltersBlock {...props} onFilter={onFilter} query={query} onSearch={onSearch} filters={filters} viewButton={false} />
        <CreateProduct list={list} afterUpdate={fetch} />
        <EditIsActive filters={filters} query={query} selected={selectedRow} finalCountRecords={finalCountRecords} afterUpdate={fetch} />
        <EditProfit filters={filters} query={query} selected={selectedRow} finalCountRecords={finalCountRecords} afterUpdate={fetch} />
        <ResetProfit filters={filters} query={query} selected={selectedRow} finalCountRecords={finalCountRecords} afterUpdate={fetch} />
        <div style={{marginTop: '7px', marginRight: '24px'}}>
        for <b>{finalCountRecords}</b> {finalCountRecords === 1 ? 'game' : 'games'}
        </div>
        <UploadKeys afterSuccess={fetch} className="mr-small"/>
    </div>
    {!isConfigProcessing ? 
    <ProductsTable
    list={list}
    isLoading={isLoading}
    afterUpdate={fetch}
    filters={filters}
    query={query}
    pagination={pagination}
    selectedRowKeys={selectedRow}
    onChange={onChange}
    onSelectChange={onSelectChange}
    onFilter={onFilter}
     /> : <Spin />

    }
    
    </>
  );
}

export default Dashboard;
