import React, { useCallback } from 'react';
import s from './styles.module.scss'
import { Spin } from 'antd';
import { CheckCircleTwoTone, LoadingOutlined} from '@ant-design/icons';

interface ColumnDescription {
  isCompleted: boolean,
  descr: string
};

const ColumnDescription: React.FC<ColumnDescription> = ({ isCompleted, descr }) => {

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  return (
    <>
    <br />
    <div className={s.withTwoLine}>

        {
          !isCompleted
          ? <Spin indicator={antIcon} />
          : <CheckCircleTwoTone twoToneColor="#52c41a" />
        }
        <span style={{marginLeft: '10px'}}>{descr}</span>
        </div>
    
    </>
  );
}

export default ColumnDescription;
