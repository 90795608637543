import React from 'react';
import { Radio } from 'antd';
import { Filter } from '../../../../types/rootState';
import { MoreHorizTwoTone } from '@mui/icons-material';

const ProductStatusGroupButton: React.FC<Filter> = ({ onChange, value = '', className }) => {
  return (
    <>
      <div>
      <MoreHorizTwoTone style={{fontSize: '12px', marginRight: '4px', color: 'rgb(24, 144, 255)'}} />
        <b>Product status</b>
      </div>
      <div className={className}>
        <Radio.Group onChange={onChange} value={value}>
          <Radio.Button value="true">Active</Radio.Button>
          <Radio.Button value={null}>All</Radio.Button>
          <Radio.Button value="false">Disabled</Radio.Button>
        </Radio.Group>
      </div>
    </>
  );
}
export default ProductStatusGroupButton;
