import { applyMiddleware, createStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'react-router-redux';
import { createBrowserHistory } from 'history';
import frc from 'front-end-common';
import rootReducer from '../reducers';
import { composeWithDevTools } from 'redux-devtools-extension';

const utilMiddleware = frc.utils.reducerTools.middleware;

export const history = createBrowserHistory();

function store (initialState = {}) {
  let middleware = applyMiddleware(utilMiddleware, routerMiddleware(history));

  return createStore(rootReducer, initialState, composeWithDevTools(middleware));
};

export default store;
