const marketToString = (id, mktObj) => {
  let market = '';

  for (let key in mktObj) {
    if (mktObj[key] === id) {
      market = key;
      break;
    }
  }

  return market;
}

export default marketToString;
