import React from 'react';
import PopoverEditable from '../../../components/PopoverEditable';
import ValidateMinCurrentProfit from '../../../components/ChangeMinCurrentProfit/ValidateMinCurrentProfit';
import ChangeMinCurrentProfit from '../../../components/ChangeMinCurrentProfit'
import MinCurrentProfit from '../../../components/MinProfit';
import XBOXSetDefaultProfit from '../../../components/SetDefaultProfit';
import s from './styles.module.scss'
import { UpdateValues, UpdateValuesToggle } from '../../../types/rootState';


interface ColumnProfit {
  value?: number
  isLoading?: boolean
  handleUpdateProfit?: (data: UpdateValuesToggle[]) => void
  mktProductId: string
  profit?: number
  profitMin?: number
  handleProfitOverwriteToggle?: (data: UpdateValues[]) => void
  mktId: number
  isDefault: boolean
  profitMinDef: number
  profitDef: number
};

const ColumnProfit: React.FC<ColumnProfit> = ({
  isLoading,
  handleUpdateProfit,
  mktProductId,
  profit,
  profitMin,
  profitMinDef,
  profitDef,
  handleProfitOverwriteToggle,
  mktId,
  isDefault
}) => {
  return (
    <div className="XBOXProductTable__profitColumn">
      <PopoverEditable
        initialValue={[profitMin, profit]}
        withTitle
        title="Profit (min / current)"
        isLoading={isLoading as boolean}
        // @ts-ignore
        handleSave={([newProfitMin, newProfit]: [number, number]) => {
          if (!ValidateMinCurrentProfit(profitMin, newProfitMin, profit, newProfit)) {
            return;
          }

          handleUpdateProfit([
            {
              mktId,
              mktProductId,
              profitMax: newProfit && newProfit / 100,
              profitMin: newProfitMin && newProfitMin / 100,
            },
          ]);
        }}
        iconClassName={s.editIcon}
        changeBlock={(props: any) => <ChangeMinCurrentProfit profitMin={profitMin} profit={profit} {...props} />}
      >
        <MinCurrentProfit isDefault={isDefault} profitMin={isDefault ? Math.round(profitMinDef * 100) : Math.round((profitMin as number) * 100)} currentProfit={isDefault ? Math.round(profitDef * 100) : Math.round(profit as number * 100)} className="cursor-pointer" />
      </PopoverEditable>
      <XBOXSetDefaultProfit
        isOverwrite={[!!profit, !!profitMin].includes(true)}
        mktId={mktId}
        mktProductId={mktProductId as string}
        handleProfitOverwriteToggle={handleProfitOverwriteToggle as (data: UpdateValues[]) => void}
        value="profit"
      />
    </div>
  );
}



export default React.memo(ColumnProfit);
