import React from 'react';
import { Modal, Button } from 'antd'
import { JSONTree } from 'react-json-tree'
import frc from 'front-end-common';
import s from './styles.module.scss';
import DatabaseOutlined from '@ant-design/icons/lib/icons/DatabaseOutlined';


const theme = {
  scheme: 'monokai',
  author: 'wimer hazenberg (http://www.monokai.nl)',
  base00: '#272822',
  base01: '#383830',
  base02: '#49483e',
  base03: '#75715e',
  base04: '#a59f85',
  base05: '#f8f8f2',
  base06: '#f5f4f1',
  base07: '#f9f8f5',
  base08: '#f92672',
  base09: '#fd971f',
  base0A: '#f4bf75',
  base0B: '#a6e22e',
  base0C: '#a1efe4',
  base0D: '#66d9ef',
  base0E: '#ae81ff',
  base0F: '#cc6633',
};

const useBoolean = frc.hooks.useBoolean;

function PopupWithJson({ title, content }) {
  const { setTrue: open, value: isOpen, setFalse: close } = useBoolean(false);

  async function copy() {
      await navigator.clipboard.writeText(JSON.stringify(content))
  }

  return <>
    <DatabaseOutlined style={{fontSize: '10px', width: '10px', color: '#1677ff',marginLeft: '2px'}} onClick={open}/>
    <Modal visible={isOpen} destroyOnClose closable onCancel={close} footer={null} width={"90vw"} centered style={{maxWidth: "500px"}} >
      {
        Array.isArray(content)
          ? content.map((item) => <div key={item}>
              <Button
                disabled={!content}
                onClick={copy}
                className={s.button}
              >
                Copy
              </Button>
              <JSONTree invertTheme={true} hideRoot theme={theme} data={item} />
            </div>)
          : <div>
              <Button
                disabled={!content}
                type="primary"
                onClick={copy}
                className={s.button}
              >
                Copy
              </Button>
              <JSONTree invertTheme={true} shouldExpandNode={() => true} hideRoot theme={theme} data={content} />
          </div>
      }
    </Modal>
  </>;
}

export default PopupWithJson;
