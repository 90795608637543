//@ts-nocheck
import React, { useCallback, useEffect, useState} from 'react';
import { Button, Cascader, Divider, Select, TreeSelect, Spin } from 'antd';
import { debounce } from 'lodash';
import { lookupDigisel, lookupPlatiMarket } from '../../../../reducers/dashboard';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import clearSpecialCharacters from '../../../../Utils/clearSpecialCharacters';

const { Option } = Select

interface LookupState {
  dashboard: {
    lookDigi: any
  }
}

function transformData(data): any {
  if (!data || !Array.isArray(data)) {
    return [];
  }

  return data.map((item) => ({
    value: [item.id, item.parentId, item.isSub].join('-'),
    title: item.name,
    children: transformData(item.children),
  }));
}

const ProductCreatePlatiMarket: React.FC<CreateMapping> = ({setMktProductId, setParentCategoryId, setCategoryId, setIsSub, mktProductId}) => {

  const dispatch = useDispatch()
  const [nestedProducts, setNestedProducts] = useState([])
  const [nestedCategories, setNestedCategories] = useState<any>([])
  const [nestedCollection, setNestedCollection] = useState<any>([])
  const [selectedCategory, setSelectedCategory] = useState('')
  const [show, setShow] = useState(false)
  const [loading, setIsLoading] = useState(false)

  // console.log('nestedCollection', nestedCollection)

  // console.log('nestedCategories', nestedCategories)

  const lookup = useSelector((state: LookupState) => state?.dashboard?.lookPlatiMkt, shallowEqual);

  const onSearch = useCallback(
    (() => {
      const debouncedLookup = debounce((value) => {
        dispatch(lookupPlatiMarket(value))
      }, 700);

      return (query: string) => {
        const cleared = clearSpecialCharacters(query);
        if (cleared.length >= 3) {
          debouncedLookup(cleared);
        }
      };
    })(),
    [],
  );

  const onChange = (value: any) => {
    const option = JSON.parse(value)
    setIsLoading(true)

    const newData = {
      id: option.id,
      parentId: option.parentId,
      isSub: option.isSub,
      name: option.name,
      children: option.children
    };

    setNestedCollection({ ...newData });
    setTimeout(() => {
      setIsLoading(false)
    }, 1000) 

  }

  useEffect(() => {
    const { id, isSub } = nestedCollection
    setMktProductId(id)
    setParentCategoryId(null)
    setCategoryId(id)
    setIsSub(isSub)

  }, [nestedCollection])

  const transformedData = nestedCollection.children && transformData(nestedCollection.children)
  // console.log(transformedData)

  const handleNested = (value) => {
    // console.log('SelectedValue', value)
    setSelectedCategory(value)
  }

  useEffect(() => {
    const [categoryId, parentCategoryId, isSub] = selectedCategory.split('-');
    setCategoryId(parseInt(categoryId));
    setParentCategoryId(parseInt(parentCategoryId));
    let parsedIsSub;
      if (isSub === '') {
        parsedIsSub = null;
      } else {
        parsedIsSub = isSub === 'true';
      }
    setIsSub(parsedIsSub)
  }, [selectedCategory])

  useEffect(() => {
    if (nestedCollection.id) setShow(true)
    else setShow(false)
  }, [nestedCollection])
  

  const filterTreeNode = (inputValue, treeNode) => {
    const title = treeNode.props.title;
    return title.toLowerCase().includes(inputValue.toLowerCase());
  };

return (
 
    <>
    
    <Select
      size="large"
      showSearch
      placeholder="Select a product"
      onSelect={onChange}
      onSearch={onSearch}
      style={{marginBottom: '12px'}}
    >
      
      {lookup?.payload?.map((option: any, index) => (
        <Option key={index} value={JSON.stringify(option)}>
          {option.name}
        </Option>
      ))}
    </Select>
    {loading ? (<Spin /> ) :  ( 
    show &&
          <>
          <TreeSelect
          treeData={transformedData}
          placeholder="Select a category"
          onChange={handleNested}
          showSearch
          filterTreeNode={filterTreeNode}
          />
          </>
      
)}
      </>
   
);

};

export default ProductCreatePlatiMarket
