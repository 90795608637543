import { rankWith, scopeEndsWith, or, schemaMatches, schemaTypeIs, schemaSubPathMatches, and, optionIs, hasType, isControl, isNumberControl } from '@jsonforms/core';

export default rankWith(
  10, //increase rank as needed
  // schemaTypeIs('number'),
  or(
    // scopeEndsWith('percent'),
    // scopeEndsWith('fixed'),
    // scopeEndsWith('whenPriceLower'),
    // isControl,
    // schemaMatches('#/properties/rate', ())
    isNumberControl
  ),
);
