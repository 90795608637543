import React from 'react';
import { DatePicker } from 'antd';
import { getFirstTime, getLastTime } from '../../../../Utils/date';
import s from './styles.module.scss'
import { Filter } from '../../../../types/rootState';
import { CalendarMonthTwoTone } from '@mui/icons-material';

const { RangePicker } = DatePicker;

const DiscountDatepicker: React.FC<Filter> = ({ onChange, value}) => {
  return (
    <>
      <div>
      <CalendarMonthTwoTone style={{fontSize: '12px', marginRight: '4px', color: 'rgb(24, 144, 255)'}} />
        <b>Discount end date</b>
      </div>
      <div className={s.datePicker}>
        <RangePicker
          format='HH:mm:ss DD/MM/YYYY'
          // format={FORMAT_WITH_TIME_FIRST}
          showTime={{ defaultValue: [getFirstTime(), getLastTime()] }}
          size='small'
          className={s.block}
          value={value}
          onChange={onChange}
          allowClear
        />
      </div>
    </>
  );
}
export default DiscountDatepicker;
