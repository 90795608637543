import { isVisible } from '@jsonforms/core';
import { TextField } from '@mui/material';
import React, { useEffect, useRef } from 'react';

const CustomNumberInput = ({ value, updateValue, path, label, props }) => {
  const textFieldRef = useRef(null);

  // console.log('props', props)

  useEffect(() => {
    const handleWheel = e => e.preventDefault();

    const textField = textFieldRef.current;
    if (textField) {
      textField.addEventListener('wheel', handleWheel);

      return () => {
        textField.removeEventListener('wheel', handleWheel);
      };
    }
  }, []);

  const handleInputChange = e => {
    const newValue = parseFloat(e.target.value);
    if (!isNaN(newValue) && typeof updateValue === 'function') {
      updateValue(newValue);
    }
  };
  
  return (
    <TextField
      style={{width: '100%', marginTop: '12px'}}
      // variant="standard"
      label={label}
      type="number"
      inputRef={textFieldRef}
      value={value ? value.toString() : ''}
      onChange={handleInputChange}
    />
  );
};

export default CustomNumberInput;
