import React, { useCallback } from 'react';
import s from './styles.module.scss'
import moment from 'moment';

interface ColumnCompletedAt {
  completed: number,
};

const ColumnCompletedAt: React.FC<ColumnCompletedAt> = ({ completed }) => {

  return (
    <>
    <br />
    <div className={s.withTwoLine}>
        <span>{completed === 0 ? '-' : moment(completed).format('HH:mm:ss')}</span>
        </div>
    
    </>
  );
}

export default ColumnCompletedAt;
