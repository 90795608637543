import React from 'react';
import { Tag } from 'antd';


interface PrintPercent {
  percent?: number,
  className?: string,
  isDefault?: boolean
};

function getColor (percent: number): string | null {
  if (+percent < 15) {
    return 'red';
  }

  if (+percent > 40) {
    return 'green';
  }

  return null;
}

const PrintPercent: React.FC<PrintPercent> = ({ percent, className, isDefault }) => {
  if (!percent || percent === null) {
    return <span className={className}>default</span>;
  }

  
  return (
    <Tag 
    // style={{ padding: '2px', margin: '2px'}}
    // @ts-ignore
    color={isDefault ? 'blue' : getColor(percent)}
    className={className}>
      {percent}%
    </Tag>
  );
}


export default PrintPercent;
