import moment from 'moment';
import dayjs from 'dayjs'

export const DEFAULT_FORMAT = 'DD/MM/YYYY';
export const DEFAULT_FORMAT_WITH_TIME = 'DD/MM/YYYY HH:mm';
export const FORMAT_WITH_TIME_FIRST = 'HH:mm:ss DD/MM/YYYY';
export const FORMAT_WITH_TIME_LAST = 'DD/MM/YYYY HH:mm:ss';
export const ONLY_FORMAT_TIME = 'HH:mm:ss';

moment.locale('en', {
  week: {
    dow: 1,
  },
});
moment.locale('en');

export function utcToDefaultFormat(date) {
  return (date && moment.utc(date).format(DEFAULT_FORMAT)) || null;
}

export function isMoment(value) {
  return moment.isMoment(value);
}

export function getCurrentUtc() {
  return moment.utc();
}

export function dateToUTC(date) {
  return moment(date).utc();
}

export function utcToDefaultFormatWithTime(date) {
  return (date && moment(date).format(DEFAULT_FORMAT_WITH_TIME)) || null;
}
export function utcToFormatWithTimeFirst(date) {
  return (date && moment(date).format(FORMAT_WITH_TIME_FIRST)) || null;
}
export function utcToFormatWithTimeLast(date) {
  return (date && moment(date).format(FORMAT_WITH_TIME_LAST)) || null;
}
export function utcToFormatOnlyTime(date) {
  return (date && moment(date).format(ONLY_FORMAT_TIME)) || null;
}

export function getDefaultRange() {
  return [
    moment()
      .subtract(1, 'month')
      .startOf('day')
      .valueOf(),
    moment()
      .endOf('day')
      .valueOf(),
  ];
}

export function unixToMoment(unix) {
  return (unix && dayjs(unix)) || null;
}

export function momentToUnix(v) {
  return (v && v.valueOf()) || null;
}

export function momentToSaleUnix(v) {
  return (v && v.set({ hour: 16, minute: 0, second: 0, millisecond: 0 }).valueOf()) || null;
}

export function momentToUnixRange([from, to]) {
  return [(from && from.startOf('day').valueOf()) || null, (to && to.endOf('day').valueOf()) || null];
}

export function getCurrentUnix() {
  return moment().valueOf();
}

export function getCurrentTime() {
  return moment();
}

export function getNextMonth() {
  return moment().add(1, 'M');
}

export function setDefaultG2ADiscountTime(date, unix = true) {
  if (!date || !moment.isMoment(date)) return null;

  if (unix) {
    return date
      .clone()
      .set('hour', 20)
      .startOf('hour')
      .unix();
  }

  return date
    .clone()
    .set('hour', 20)
    .startOf('hour');
}

export function getDateByToday(date) {
  const diffDays = moment()
    .startOf('day')
    .diff(date.clone().startOf('day'), 'days');

  if (diffDays < -1) {
    return 'next time';
  }

  if (diffDays === 0) {
    return 'today';
  }

  if (diffDays === -1) {
    return 'tomorrow';
  }

  if (diffDays > -1) {
    return 'ago';
  }

  return null;
}

export function getFirstTime() {
  return dayjs('00:00:00', 'HH:mm:ss');
}

export function getLastTime() {
  return dayjs('23:59:59', 'HH:mm:ss');
}

// Ranges

export function getLastWeekRange() {
  return [
    moment
      .utc()
      .subtract(1, 'week')
      .startOf('week'),
    moment
      .utc()
      .subtract(1, 'week')
      .endOf('week'),
  ];
}

export function getLastMonthRange() {
  return [
    moment
      .utc()
      .subtract(1, 'month')
      .startOf('month'),
    moment
      .utc()
      .subtract(1, 'month')
      .endOf('month'),
  ];
}

export function dateRangePeriods() {
  return {
    'Last Week': getLastWeekRange(),
    'Last Month': getLastMonthRange,
    Today: [moment.utc().startOf('day'), moment.utc().endOf('day')],
    'This Week': [moment.utc().startOf('week'), moment.utc().endOf('week')],
    'This Month': [moment.utc().startOf('month'), moment.utc().endOf('month')],
  };
}
