import React from 'react';

interface PrintPrice {
  price: number | undefined
  discount?: number | undefined
}

const PrintPrice:React.FC<PrintPrice> = ({ price }) => {

  if (isNaN(price as number) || !price) return <>-</>;

  return (
    <>
      {new Intl.NumberFormat('en', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(Number(price))}
    </>
  );
}

export default React.memo(PrintPrice);
