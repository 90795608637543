import React, { useCallback } from 'react';
import { Col, Row, Tooltip, message } from 'antd';
import { copyToClipboard } from '../../../Utils/textTools';
import CopyOutlined from '@ant-design/icons/lib/icons/CopyOutlined';
import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';
import s from './styles.module.scss'
import ChangeStatusLock from '../../../components/ChangeStatusLock';
import { useDispatch } from 'react-redux';
import { lockUnlock, mappingDelete } from '../../../reducers/dashboard';
import DeleteMapping from '../../../components/DeleteMapping';

interface ColumnMarkets {
  mktProductId: string,
  marketLink?: string,
  market: any,
  enabled: boolean,
  mktId: number,
  afterUpdate: any,
  autoMapper?: boolean
};

const ColumnMarkets: React.FC<ColumnMarkets> = ({autoMapper, market, mktId, marketLink, mktProductId, enabled, afterUpdate }) => {

  const dispatch = useDispatch();

  const handleLockStatusToggle = useCallback((mktId: number, mktProductId: string, value: boolean) => {
    if (!mktProductId) {
      message.error('Market product ID is absent.');
    } else {
      //@ts-ignore
      dispatch(lockUnlock({ mktId, mktProductId, value })).then(() => {
        if(value) {
          message.success('Product unlocked!');
          afterUpdate()
        } else {
          message.success('Product locked!');
          afterUpdate()
        }
        // afterUpdate()
      }).catch((error: string) => {
        console.log(error);
        message.error('Can\'t change product status!');
      });
    }
  }, [dispatch, afterUpdate]);

  const handleDeleteMapping = useCallback((mktId: number, mktProductId: string) => {
    if (!mktProductId) {
      message.error('Market product ID is absent.');
    } else {
      //@ts-ignore
      dispatch(mappingDelete({ mktId, mktProductId })).then(() => {
          message.success('Mapping deleted!');
          afterUpdate()
      }).catch((error: string) => {
        console.log(error);
        message.error('Can\'t delete mapping!');
      });
    }
  }, [dispatch, afterUpdate]);


  const handleClick = useCallback(() => {
    copyToClipboard(mktProductId);
    message.success('XBOX ID has been copied!');
    // eslint-disable-next-line
  }, [mktProductId]);
  return (
    <>
            <Row className={s.row60}>
              <Col span={24}>
              <Tooltip placement="top" title={market}>
              <img src={`${market}.png`} srcSet={`${market}.webp`} alt={"MKT"} style={mktId === 4 ? {width: '25px', marginRight: '8px'} : {width: '18px', marginRight: '8px', borderRadius: '50%'}} loading='lazy'/>
              </Tooltip>
              <a target="_blank" rel="noopener noreferrer" href={marketLink} className={s.linkToMarket}>
                {mktProductId}
              </a>
              <CopyOutlined style={{fontSize: '10px', marginLeft: '2px'}} onClick={handleClick} />
              {!autoMapper && 
              <>
              <ChangeStatusLock value={enabled} mktId={mktId} id={mktProductId} handleLockStatusToggle={handleLockStatusToggle} />
              <DeleteMapping mktId={mktId} id={mktProductId} handleDeleteMapping={handleDeleteMapping} />
              </>
              }
              </Col>
              </Row>
    
    </>
  );
}


export default ColumnMarkets;
