import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useBoolean from '../../../Utils/useBoolean';
import {Button, message, Popconfirm} from 'antd';
import s from './styles.module.scss'
import { groupUpdateProducts, groupUpdateStatus } from '../../../reducers/dashboard';
import { Filters } from '../../../types/rootState';
import splitArray from '../../../Utils/splitArray';

interface GroupChange {
  afterUpdate: any,
  selected: string[],
  finalCountRecords: number,
  filters: Filters,
  query: string
}

type ActionData = {
  isActive: boolean,
  hasStock?: boolean
}

const EditIsActive: React.FC<GroupChange> = ({
                     afterUpdate,
                     selected,
                     finalCountRecords,
                     filters,
                     query,
                   }) => {

  const dispatch = useDispatch();

  const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);
  // console.log('SELECTED', selected)

  const handleSuccess = useCallback((data: ActionData) => {
    
    if (selected.length) {
    const newArr = selected.map((el) => el.split(','))
    const preparedArr = splitArray(newArr)
    
    const handleData = preparedArr.map((item) => {
      return {...item, ...data}
    })
    //@ts-ignore
    dispatch(groupUpdateProducts(handleData)).then(() => {
      message.success('Actions have been changed!');
      afterUpdate();
    }).catch((error: any) => {
      // console.log(error);
      message.error('Can\'t edit actions.');
    });
    close();
    } 

    if (!selected.length) {
      const paramsData = {
        status: [data],
        filters,
        query,
      }
      //@ts-ignore
      dispatch(groupUpdateStatus(paramsData)).then(() => {
        message.success('Actions have been changed!');
        afterUpdate();
      }).catch((error: any) => {
        // console.log(error);
        message.error('Can\'t edit actions.');
      });
      close();

    }
    // const rowsArr = selected.map((item) => {
    //   const rowArr = item.split('_')
    //   // if (rowArr.length > 1) 
    //   // {
    //   // rowArr[0].split(',') => 
    //   // rowArr[1].split(',')
    //   // }
    //   return {
    //     'pltId': +rowArr[0], //rowArr[0].split(',')
    //     'pltProductId': rowArr[1],
    //     'pltRegion': rowArr[2],
    //     ...data
    //   }
    // }) 
  }, [dispatch, afterUpdate, close, selected]);
  
  return (
    <div className={s.changeRatios}>
     <div className={s.actionToggle}>
        <span
        className={s.actionToggleText}
        // style={ !finalCountRecords.length ? { color: '#00000040'} : {} }
        >Active</span>
        <Popconfirm
          title={`Are you sure to turn off ${finalCountRecords} ${finalCountRecords === 1 ? 'product' : 'products'}?`}
          onConfirm={() => handleSuccess({'isActive': false})}
          okText="Yes"
          cancelText="No"
        >
          <Button  disabled={!finalCountRecords} type="primary">Off</Button>
        </Popconfirm>
        <Popconfirm
          title={`Are you sure to sell from stock ${finalCountRecords} ${finalCountRecords === 1 ? 'product' : 'products'}?`}
          onConfirm={() => handleSuccess({'isActive': true, 'hasStock': true})}
          okText="Yes"
          cancelText="No"
        >
          <Button  disabled={!finalCountRecords} type="primary">Stock</Button>
        </Popconfirm>
        <Popconfirm
          title={`Are you sure to turn on ${finalCountRecords} ${finalCountRecords === 1 ? 'product' : 'products'}?`}
          onConfirm={() => handleSuccess({'isActive': true, 'hasStock': false})}
          okText="Yes"
          cancelText="No"
        >
          <Button disabled={!finalCountRecords} type="primary">On</Button>
        </Popconfirm>
      </div>
    </div>
  );
};

export default React.memo(EditIsActive);
