import { combineReducers } from 'redux';
import auth from './auth';
import dashboard from './dashboard';
import fuse from './fuse';
import automapper from './automapper'

export default combineReducers({
  auth,
  dashboard,
  fuse,
  automapper
});
