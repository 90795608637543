import { FloatButton } from 'antd'
import React, { useEffect, useState } from 'react';
import SettingOutlined from '@ant-design/icons/lib/icons/SettingOutlined';
import useBoolean from '../../Utils/useBoolean';
import ConfigModal from './ConfigModal';

const DashboardConfig = ({isConfigProcessing, setIsConfigProcessing}) => {

  const { value: visible, setTrue: open, setFalse: close } = useBoolean(false);

  const [statisticsSorter, setStatisticSorter] = useState('')

  useEffect(() => {
    if (typeof window !== undefined) {
      const retractedValue = localStorage.getItem('statistics_sorter') || 'sold'
      setStatisticSorter(retractedValue)
    }

    if (!localStorage.getItem('statistics_sorter') && typeof window !== undefined) {
      localStorage.setItem('statistics_sorter', 'sold')
    }
  }, [statisticsSorter])

  const handleOnClick = () => {
    // dispatch(getQuantityOnStock(pltId, productId, region), [])
    //   .then((res) => setQuantityOnStock(res.value.qty))
    open() 
  }

  const onOk = () => {
    setIsConfigProcessing(true)
    setTimeout(() => {
      close()
      setIsConfigProcessing(false)
    }, 4000)
    
  }

  return (
    <>
    <FloatButton type="primary" icon={<SettingOutlined />} onClick={handleOnClick} />
      <ConfigModal
        isConfigProcessing={isConfigProcessing}
        statisticsSorter={statisticsSorter}
        setStatisticSorter={setStatisticSorter}
        onOk={onOk}
        close={close}
        visible={visible} 
        />
    </>
   
  )
}

export default DashboardConfig
