import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { LogoutOutlined } from '@ant-design/icons';
import { authSignOut } from '../../reducers/auth';

function Logout() {
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    dispatch(authSignOut());
  }, [dispatch]);

  return <LogoutOutlined type="logout" style={{ cursor: 'pointer', color: '#1890ff', fontSize: '1.2rem' }} onClick={handleClick} />;
}

export default React.memo(Logout);
