import React from 'react';
import PrintAmount from '../../../components/Print/PrintAmount';
import cx from '../../../Utils/cx';
import s from './styles.module.scss'
import PrintAmountSmall from '../../../components/Print/PrintAmountSmall';
import { Tag, Tooltip } from 'antd';


interface ColumnXBOXPrice {
  xbox_discount?: number,
  pltDiscountPct?: number,
  pltPrice?: number | undefined,
  xbox_price_eur?: number,
  priceInStock?: number,
  useStockPrice?: boolean,
  pltCurrency?: string | undefined,
  pltPriceCur: number,
  discount: number,
  defaultPrice: number
};

const ColumnXBOXPrice:React.FC<ColumnXBOXPrice> = ({
  xbox_discount,
  pltPrice,
  xbox_price_eur,
  pltCurrency,
  pltDiscountPct,
  priceInStock,
  useStockPrice,
  pltPriceCur,
  discount,
  defaultPrice
}) => {

// - базовая цена - pltDfltPrice EUR
// - цена  ключа стоке - stockPrice (добавить)
// - текущая цена - pltPrice
// - размер скидки в процентах - discount %
// - возможность сортировать(убирать не нужные значения и также оставить возможность сортировки по ценам(от самой дорогой к дешевой и наооборот)
  return (
    <div
      className={cx(s.withTwoLine, s.withPercent)}
    >

      {/* {xbox_discount ? (
        <>
          <PrintAmount currency="EUR" amount={pltDiscountPct * 100} classNameAmount="color-orange" />
          <br />
          <small>
            <PrintAmount currency={xbox_cur} amount={xbox_discount * 100} classNameAmount="color-orange" />
          </small>
        </>
      ) : ( */}
        <>
        <Tooltip title={pltPrice < defaultPrice ? "Price with discount" : "Default price"} placement='right'>
          <Tag color={pltPrice < defaultPrice ? "green" : "default"} className="price-tag">
            <PrintAmount currency={"EUR"} amount={pltPrice < defaultPrice ? (+pltPrice * 100) : (+defaultPrice * 100)} />
            </Tag>
        </Tooltip>
        {/* <br /> */}
        {pltPrice !== defaultPrice &&
        <Tooltip title="Default price" placement='right'>
          <Tag color="default" className="price-tag">
            <PrintAmountSmall currency={"EUR"} amount={(+defaultPrice * 100)} />
          </Tag>
        </Tooltip>
}
          {/* <br /> */}
          {pltCurrency !== "EUR" && 
          <Tooltip title={`Currency price in ${pltCurrency}`} placement='right'>
              <Tag color="cyan" className="price-tag">
                <PrintAmountSmall currency={pltCurrency} amount={+pltPriceCur * 100} />
              </Tag>
          </Tooltip>
          }
      {useStockPrice && priceInStock && (
          <Tooltip title="Stock price" placement='right'>
              <Tag color="gold" className="price-tag">
                <PrintAmountSmall currency={pltCurrency} amount={+priceInStock * 100} />
              </Tag>
          </Tooltip>
      )}
          {/* <br />
          <small>
            <PrintAmount currency={pltCurrency} amount={pltPrice * 100} />
          </small> */}
        
        {/* {useStockPrice && priceInStock && (
        
        {/* <br /> */}
        {/* {useStockPrice && priceInStock &&  <PrintAmount currency={pltCurrency} amount={priceInStock * 100} classNameAmount="color-darkorchid" />} */}
          
        
      {/* )} */}
      </>
      {/* <br /> */}
      {/* <small style={{marginTop: '8px'}}>
      <Tag color={discount > 0 ? "green" : "default"}>{discount}%</Tag>
      </small> */}
      {/* )} */}
    </div>
  );
}

export default React.memo(ColumnXBOXPrice);
