// new api
export const core = `/api/v3`
// for xbox lookup
export const jukebox = '/jukebox/api/v1';
// for ggsel lookup
export const ggsel = '/digiseller/api/v1'
// for gmvo lookup
export const gamivo = `/gamivo/api/v1`;
// for kinguin lookup
export const kinguin = `/kinguin/xbox/products`;
// for g2a lookup
export const arena = '/arena/api/v2';
// for cargo requests and stock
export const cargo = '/cargo/api/v2';
// for merchant
export const merchant = '/merchant/api/v2';

// for auto mapper
export const autoMapper = '/automapper/api/v1';

