import React from 'react';
import cx from '../../Utils/cx';

interface PrintAmount {
  amount: number,
  currency: string | undefined,
  className?: string,
  classNameAmount?: string,
  colorize?: boolean,
  withZero?: boolean,
};

function getColor(amount: number) {
  if (amount < 0) {
    return 'color-red';
  }
  if (amount > 0) {
    return 'color-green';
  }

  return '';
}

const PrintAmountSmall: React.FC<PrintAmount> = ({ amount, currency, className, colorize, withZero, classNameAmount }) => {
  const classColorize = getColor(amount);
  if (amount || withZero) {
    return (
      <span className={className}>
        <small style={{ marginRight: 3, fontSize: '10px' }}>{currency}</small>
        <b style={{ fontSize: '10px' }} className={cx((colorize && classColorize) || '', classNameAmount)}>
          {(amount / 100).toFixed(2).toLocaleString()}
        </b>
      </span>
    );
  }

  return (
    <span className={className}>
      <i>none</i>
    </span>
  );
}

export default PrintAmountSmall;
