import ApiService from './ApiService';
import { autoMapper } from './Constants';



const AutoMappingService = {

  // fetchList() {
  //   return ApiService.get(`${autoMapper}/proposal`);
  // },

  fetchList({ filters, ...params }) {

    const updatedParams = { ...params };

    if (filters && Object.keys(filters).length) {
      for (let key in filters) {
        updatedParams[key] = filters[key];
      }
    }

    return ApiService.get(`${autoMapper}/proposal`, updatedParams);
  },

  decline(data) {
    return ApiService.post(`${autoMapper}/proposal`, data)
  },
  approve(data) {
    return ApiService.post(`${autoMapper}/mapping`, data)
  },


};

export default AutoMappingService
