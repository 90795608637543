import React from 'react';
import { Input } from 'antd';
import { Filter } from '../../../../types/rootState';

const { Search } = Input;

interface SearchInput extends Filter {
  onSearch: any,
  value?: string
}

const SearchNameOrId: React.FC<SearchInput> = ({ value = '', onChange, onSearch }) => {

  return (
    <Search
      value={value}
      onChange={onChange}
      placeholder="ProductID or Name"
      className="block"
      size="large"
      onSearch={onSearch}
    />
  );
}

export default SearchNameOrId;
