import { Radio } from 'antd'
import React, { useEffect, useState } from 'react'

const options = [
  { label: 'Sold', value: 'sold' },
  { label: 'Profit', value: 'profit' },
  { label: 'ProfitPercent', value: 'profitPct' },
  { label: 'Profit per Key', value: 'profitPK' },
];

interface ConfigSwitcher {
  setStatisticSorter: any,
  statisticsSorter: string
}

const StatisticsSwitcher: React.FC<ConfigSwitcher> = ({ statisticsSorter, setStatisticSorter }) => {

  const [value, setValue] = useState('sold');

  // useEffect(() => {
  //   if (typeof window !== undefined) {
  //     const retractedValue = localStorage.getItem('statistics_sorter') || 'sold'
  //     setValue(retractedValue)
  //   }
  // }, [value])
  
  

  const handleSwitchStatistics = (e: any) => {
    console.log('statchange', e.target.value)
    // setValue(e.target.value)
    localStorage.setItem('statistics_sorter', e.target.value)
    setStatisticSorter(e.target.value)
  }


  return (
    <Radio.Group
        options={options}
        onChange={handleSwitchStatistics}
        value={statisticsSorter}
        optionType="button"
        buttonStyle="solid"
      />
  )
}

export default StatisticsSwitcher
