import React from 'react';
import s from './styles.module.scss';

interface AuthLayout {
  children: any,
  className: string
}

const AuthLayout: React.FC<AuthLayout> = ({ children, className }) => {
  return (
    <div className={s.layout}>
      <div className={className}>{children}</div>
    </div>
  );
}

export default AuthLayout;
