import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { autoMapperListFetch } from '../../reducers/automapper';
import useTable from '../../Utils/useTable';
import AutoMapperTable from './Table';
import FiltersBlock from '../../components/Filters';

const AutoMappingTable = (props: any) => {

  const { list, isLoading, fetch, filters, query, onSearch, onFilter, pagination, onChange } = useTable((state: any) => state.automapper.getList, autoMapperListFetch)


  return (
    <>
    <FiltersBlock {...props} onFilter={onFilter} query={query} onSearch={onSearch} filters={filters} isLoading={isLoading} forAutoMapping />
      {/* @ts-ignore */}
      <AutoMapperTable
      list={list}
      isLoading={isLoading}
      afterUpdate={fetch}
      filters={filters}
      query={query}
      pagination={pagination}
      onChange={onChange}
      onFilter={onFilter}
      />
     </>
  )
}

export default AutoMappingTable
