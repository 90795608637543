//@ts-nocheck
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Button, message, Typography } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import cx from '../../../Utils/cx';
import LoadingBlock from '../../../components/LoadingBlock';
import {
  fuseSettingsFetch,
  fuseSettingsFetchReset,
  fuseSettingsSchema,
  fuseSettingsUISchema,
  fuseSettingsUpdate,
  fuseSettingsUpdateReset,
} from '../../../reducers/fuse';
import deepEqual from '../../../Utils/deepEqual';
import useBoolean from '../../../Utils/useBoolean';
import { JsonForms } from '@jsonforms/react';
import { materialCells, materialRenderers } from '@jsonforms/material-renderers';
import Ajv from 'ajv';
import _ from 'lodash';
import s from './styles.module.scss'
import customInputTester from '../../../components/CustomRenderers/customInputTester';
import CustomRenderers from '../../../components/CustomRenderers';

// import mockData from './mockData.json'
// import mockSchema from './mockSchema.json'
// import mockUISchema from './mockUISchema.json'

const { Title } = Typography;

function SteamSettings() {
  const dispatch = useDispatch();

  const { value: isVisibleSave, setFalse: setVisibleSave, setTrue: setUnVisibleSave } = useBoolean(true);

  const [stateSettings, setStateSettings] = useState({})
  const [schema, setSchema] = useState({})
  const [uiSchema, setUISchema] = useState({})

  // console.log('stateSettings', stateSettings)
  // console.log('schema', schema)
  // console.log('uiSchema', uiSchema)

  useEffect(() => {
    
      // retrieve data
      dispatch(fuseSettingsFetch())
      .then((res) => {
        console.log('res', res)
        setTimeout(() => {
          setStateSettings(res)
        }, 1400)
      })
      .catch((error) => {
        message.error(`Failed to retrieve data: ${error}`)
      })
      .finally(() => {
        console.log("Success data retrieve")
      })

      // retrieve schema
      dispatch(fuseSettingsSchema())
      .then((res) => {
        console.log('resSchema', res)
        
        setTimeout(() => {
          setSchema(res)
        }, 1200)
      })
      .catch((error) => {
        message.error(`Failed to retrieve schema: ${error}`)
      })
      .finally(() => {
        console.log("Success schema retrieve")
      })

    // retrieve ui schema
      dispatch(fuseSettingsUISchema())
      .then((res) => {
        console.log('resUiSchema', res)
        setTimeout(() => {
          setUISchema(res)
        }, 1000)
      })
      .catch((error) => {
        message.error(`Failed to retrieve ui schema: ${error}`)
      })
      .finally(() => {
        console.log("Success ui schema retrieve")
      })
    
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { settings, updateSettingsLoading, settingsSchema, settingsUISchema } = useSelector(
    (state) => ({
      settings: state.fuse?.settingsFetchFuse,
      updateSettingsLoading: state.fuse?.fuseSettingsUpdate?.isLoading,
      settingsSchema: state.fuse?.fuseSettingsSchemaFetch,
      settingsUISchema: state.fuse?.fuseSettingsUiSchemaFetch,
    }),
    shallowEqual,
  );

  // console.log('schema', settingsSchema)
  

  // Payload for settings
  const { dataPayload, settingsPayload, uiSettingsPayload } = {
    dataPayload: settings.payload,
    settingsPayload: settingsSchema.payload,
    uiSettingsPayload: settingsUISchema.payload
  }

  useEffect(() => {
    if (settings?.isLoading || (settings?.payload && deepEqual(stateSettings, settings?.payload))) {
      setUnVisibleSave();
      return;
    }
    setVisibleSave();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateSettings, settings]);

  // useEffect(() => {
  //     if(settingsPayload) {
  //     setSchema(settingsPayload)
  //   } else if (settingsError) {
  //     message.error(`SCHEMA ERROR: ${settingsError}`)
  //   }
  // }, [settingsPayload, settingsError])

  // useEffect(() => {
  //   // if(settingsUISchema?.payload) {
  //     if (uiSettingsPayload) {
  //     // const obj = {
  //     //   ...uiSchema,
  //     //   type: settingsUISchema?.payload.type,
  //     //   elements: settingsUISchema?.payload.elements
  //     // }
  //     setUISchema(uiSettingsPayload)
  //   }
  // }, [uiSettingsPayload])
  
  // useEffect(() => {
  //   if (dataPayload?.code === 500) {
  //     message.error(`Error getting response`)
  //   } else {
  //     setStateSettings(dataPayload)
  //   }
  // }, [dataPayload]); // eslint-disable-line react-hooks/exhaustive-deps

  function compareObjects(obj1, obj2) {
    let arr = []
    for (const key in obj1) {
      if(JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
          arr.push({name: key, value: JSON.stringify(obj2[key])})
        }
    }
    return arr
  }

  const ajv = new Ajv();


  const handleSave = useCallback((e) => {
    // const copy = normalizeData(stateSettings)
    const copy = {...stateSettings}
    // transform into { 'name': 'string', 'value': 'string' }
    // delete dataPayload['steamDiscountExpired.min']
    const copiedData = compareObjects(dataPayload, copy)
    // console.log('COPY', copy)

    const validate = ajv.compile(schema)
    const valid = validate(copy)
    if(!valid) {
      message.error('Please fill all data correctly')
    } else {
    dispatch(fuseSettingsUpdate(copy))
      .then(() => message.success('Success update Fuse settings'))
      .catch((e) => message.error(`Error update Fuse settings: ${e}`))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateSettings]);

  useEffect(
    () => () => {
      dispatch(fuseSettingsFetchReset());
      dispatch(fuseSettingsUpdateReset());
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  // console.log('SETTINGS', stateSettings)
  // console.log('SCHEMA', schema)
  // console.log('MOCK', mockSchema)

  const renderers = [
    ...materialRenderers,
    //register custom renderers
    { tester: customInputTester, renderer: CustomRenderers },
  ];

  useEffect(() => {
    const handleWheel = (e) => e.preventDefault();

    const inputFields = document.querySelectorAll('input[type="number"]');

    inputFields.forEach((inputField) => {
      inputField.addEventListener('wheel', handleWheel);

      return () => {
        inputField.removeEventListener('wheel', handleWheel);
      };
    });
  });
  

  return (
    <Fragment>
      <Title level={4} className={cx(s.title, s.mb)}>
        Settings
        <Button type="primary" onClick={(e) => handleSave(e)} disabled={isVisibleSave} loading={updateSettingsLoading}>
            Save
        </Button>
      </Title>
      <LoadingBlock loading={settings?.isLoading || settingsSchema?.isLoading || settingsUISchema?.isLoading}>
        {/* <Descriptions bordered className="mb">  */}
      <div> 
        <JsonForms
          schema={schema}
          uischema={uiSchema}
          data={stateSettings}
          cells={materialCells}
          renderers={materialRenderers}
          validationMode={'ValidateAndShow'}
          onChange={({errors, data}) => setStateSettings(data)}
            />
      </div>
      </LoadingBlock>
      </Fragment>
  );
}

export default SteamSettings;
