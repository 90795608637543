import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useBoolean from '../../Utils/useBoolean';
import {Drawer, Form, Button, message, InputNumber, Input} from 'antd';
import { EditFilled } from '@ant-design/icons';
import { groupUpdateProducts, groupUpdateProfit, updatePltProductId } from '../../reducers/dashboard';
import { Filters } from '../../types/rootState';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';


interface GroupChange {
  afterUpdate: any,
  mktRows: any,
}

const EditPlatformProductId: React.FC<GroupChange> = ({
                     afterUpdate,
                     mktRows,
                   }) => {

  const dispatch = useDispatch();

  const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);

  const handleSuccess = useCallback((data: any) => {

    const {pltProductId} = data
    const updatedMktRows = [];

      for (const obj of mktRows) {
        const updatedObj = {
          pltProductId,
          pltRegion: obj.pltRegion,
          mktId: obj.mktId,
          mktProductId: obj.mktProductId
        };
        updatedMktRows.push(updatedObj);
      }
    
    dispatch(updatePltProductId(updatedMktRows))
    //@ts-ignore
    .then(() => {
      message.success('Id has been updated!');
      close()
      afterUpdate();
    }).catch((error: string) => {
      message.error(`Can\'t update ID: ${error} `);
    });
  }, [dispatch, afterUpdate, close]);
  
  return (
    <>
      <Drawer open={isOpen} title="Edit Platform Product ID" width={300} closable placement="right" onClose={close}>
        <Form layout="vertical" onFinish={handleSuccess}>
          <Form.Item
            label="New ID"
            name="pltProductId"  
            rules={[
              {
                required: true,
                message: 'please enter new ID',
              },
          ]}>
            <Input />
          </Form.Item>
          
          <Form.Item>
            <Button
                  type="primary"
                  htmlType="submit"
              >
                  Submit
              </Button>
          </Form.Item>
        </Form>
      </Drawer>
      <EditOutlined onClick={open} style={{fontSize: '10px', marginLeft: '2px', width: '10px'}} />
    </>
  );
};

export default React.memo(EditPlatformProductId);
