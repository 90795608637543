//@ts-nocheck
import React, { useEffect, useMemo } from 'react';
import { Radio } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { statusToText } from '../../Utils/textTools';
import LoadingBlock from '../LoadingBlock';
import { fetchRegions } from '../../reducers/dashboard';

const RadioRegionsXbox = (props, ref) => {
  const { isLoading, regions } = useSelector(
    (state) => ({
      regions: state?.dashboard?.regionsXbox?.payload,
      isLoading: state?.dashboard?.regionsXbox?.isLoading,
    }),
    shallowEqual,
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (!regions) {
      dispatch(fetchRegions());
    }
  }, [dispatch, regions]);

  const options = useMemo(() => {
    if (regions) {
      return regions.map((region) => ({
        label: statusToText(region),
        value: region,
      }));
    }
    return [];
  }, [regions]);

  // const handleSelectRegion = (e) => {
  //   setRegion(e.target.value)
  // }

  return (
    <LoadingBlock loading={isLoading} noMinHeight>
      <Radio.Group
      options={options}
      {...props}
      ref={ref}
      // onChange={handleSelectRegion} 
      />
    </LoadingBlock>
  );
}

export default React.forwardRef(RadioRegionsXbox);
