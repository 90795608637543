import { useEffect } from 'react';

export default function useDebounce(callback, ms = 0, ...args) {
  useEffect(() => {
    const handler = setTimeout(callback.bind(null, ...args), ms);

    return () => clearTimeout(handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, args);
}
