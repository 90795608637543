import React, { useCallback } from 'react';
import { Input } from 'antd';
import PercentageOutlined from '@ant-design/icons/lib/icons/PercentageOutlined';

interface ChangeMinCurrentProfit {
  value: number[],
  handleChange: any,
  className?: string,
  profitMin: number,
  profit: number
};

const ChangeMinCurrentProfit: React.FC<ChangeMinCurrentProfit> = ({ value, handleChange, className, profitMin, profit}) => {
  const onChange = useCallback(
    (v: any, index: number) => {
      const newValue = [...value];
      newValue[index] = v;
      handleChange(newValue);
    },
    [value, handleChange],
  );

  return (
    <div className={className}>
      <div style={{ display: 'flex' }}>
        <Input
          size="small"
          style={{ display: 'flex', width: '120px' }}
          //@ts-ignore
          placeholder={Math.round(profitMin * 100)}
          type="number"
          addonAfter={<PercentageOutlined />}
          //@ts-ignore
          value={Math.round(value[0]) || null}
          onChange={(e) => onChange(+e.target.value, 0)}
        />
        <span className="mr-small ml-small">/</span>
        <Input
          size="small"
          style={{ display: 'flex', width: '120px' }}
          //@ts-ignore
          placeholder={Math.round(profit * 100)}
          type="number"
          addonAfter={<PercentageOutlined />}
          //@ts-ignore
          value={Math.round(value[1]) || null}
          onChange={(e) => onChange(+e.target.value, 1)}
        />
      </div>
    </div>
  );
}

export default ChangeMinCurrentProfit;
