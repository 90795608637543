import { Radio } from 'antd';
import React from 'react';
import { Filter } from '../../../../types/rootState';
import s from './styles.module.scss'

interface CommonRadioGroup extends Filter {
  title: string
  icon: any
}

const CommonRadioGroup: React.FC<CommonRadioGroup> = ({ onChange, value, title, icon })  =>{
  return (
    <>
      <div>
        {icon}
        <b>{title}</b>
      </div>
      <div className={s.regionSelect}>
        <Radio.Group onChange={onChange} value={value}>
          <Radio.Button value="true">Stock</Radio.Button>
          <Radio.Button value={null}>All</Radio.Button>
          <Radio.Button value="false">No Stock</Radio.Button>
        </Radio.Group>
      </div>
    </>
  );
}

export default CommonRadioGroup;
