import { useEffect, useState } from 'react';

function useStorageState(key, defaultValue) {
  let prevValue = defaultValue;

  try {
    const storageValue = localStorage.getItem(key);
    if (storageValue) {
      prevValue = JSON.parse(storageValue);
    }
  } catch (e) { }

  const [value, setValue] = useState(prevValue);

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [value, key]);

  return [value, setValue];
}

export default useStorageState
