import React, { useCallback } from 'react';
import s from './styles.module.scss'
import { Spin } from 'antd';
import { CheckCircleTwoTone, LoadingOutlined} from '@ant-design/icons';

interface ColumnNumber {
  quantity: number,
};

const ColumnNumber: React.FC<ColumnNumber> = ({ quantity }) => {

  return (
    <>
    <br />
    <div className={s.withTwoLine}>
        <span>{quantity}</span>
        </div>
    
    </>
  );
}

export default ColumnNumber;
