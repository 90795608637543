//@ts-nocheck
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import type { ColumnsType } from 'antd/es/table'
import { Badge, Col, message, Row, Spin, Table, Tag, Tooltip } from 'antd'
import { ListType, ProductTableProps, UpdateValues, UpdateValuesToggle } from '../../types/rootState'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { changeAction, MarketsFetch, profitOverwrite, updateProfit } from '../../reducers/dashboard'
import s from './styles.module.scss'
import theme from '../../Utils/jsonTheme'
import ActionButtons from '../../components/ActionButtons'
import TableSettings from '../../components/TableSettings'
import useStorageState from '../../Utils/useStorageState'
import ExclamationCircleTwoTone from '@ant-design/icons/lib/icons/ExclamationCircleTwoTone';
import XboxPrice from './Columns/XboxPrice'
import ColumnDiscountExpire from './Columns/ColumnDiscountExpire'
import ColumnName from './Columns/ColumnName'
import marketToString from '../../Utils/marketToString'
import ColumnMarkets from './Columns/ColumnMarkets'
import ColumnGroup from './Columns/ColumnGroup'
import ColumnCreateMapping from './Columns/ColumnCreateMapping'
import CommentsSection from '../../components/CommentsSection'
import OrderKeysButton from '../../components/OrderKeys/OrderKeysButton'
import { Set } from 'typescript';
import StatisticsTag from '../../components/StatisticsTag';
import ColumnCurrentProfit from './Columns/Profit';
import ColumnProfit from './Columns/Profit';
import ColumnPriceG2A from './Columns/Price';
import ColumnCompetitorsMin from './Columns/CompetitorsMin';
import ColumnPriceRatio from './Columns/ColumnPriceRatio'


interface Regions {
  set?: Set<string>
  value: string | unknown
  text: string | unknown
}


let CURRENCY = "EUR"

const ProductsTable: React.FC<ProductTableProps> = ({list, isLoading, pagination, onChange, afterUpdate, filters, onSelectChange, selectedRowKeys, onFilter, query}) => {

  const [statisticsSorter, setStatisticSorter] = useState<string | null>('');

  useEffect(() => {
    if (typeof window !== undefined) { 
      const statisticSorterExtracted = localStorage.getItem('statistics_sorter') 
      setStatisticSorter(statisticSorterExtracted)
    }
  }, [])

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(MarketsFetch())
    //@ts-ignore
    .catch((error) => {
      message.error('Please Sign In');
    });
  }, [dispatch]);

  const markets = useSelector((state: any) => state.dashboard.markets.payload, shallowEqual);

  const [stock, setStock] = useState()

  const handleChangeAction = useCallback((mktId: number, mktProductId: string, data: string) => {
    const parsedData = JSON.parse(data)
    const params = {
      data: parsedData,
      filters: { ...filters },
      query,
      mktId,
      mktProductId
    };
    if (!mktProductId) {
      message.error('Market product ID is absent.');
    } else {
      //@ts-ignore
        dispatch(changeAction(params)).then(() => {
        message.success('Action has been added!');
        afterUpdate();
      }).catch((error: string) => {
        console.log(error);
        message.error('Can\'t change action.');
      });
    }
  }, [dispatch, afterUpdate, filters, query]);

  let regionsSet = new Set()
  let namesSet = new Set()

 list && Object.entries(list).forEach( ([key, value]) => {
    regionsSet.add(value?.pltRegion);
    namesSet.add(value?.pltName)
  })

  let regions: Regions[] = useMemo(() => [], [])

  // console.log('payload', regions)
  
  useEffect(() => {
    regions.length = 0
    regionsSet.forEach((item) => regions.push({'value': item, 'text': item}))
  }, [list]);

  let names: Regions[] = useMemo(() => [], [])

  useEffect(() => {
    names.length = 0
    namesSet.forEach(item => names.push({'value': item, 'text': item}))
  }, [list]);

  const handleUpdateProfit = useCallback(
    (data: UpdateValues[]) => {
      const params = {
        data,
        filters: { ...filters },
        query
      };
      dispatch(updateProfit(params))
      //@ts-ignore
        .then(() => {
          message.success('Ratio has been updated');
          afterUpdate();
        })
        .catch((e: string) => {
          message.error(`Failed to update ratio: ${e}`);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, afterUpdate],
  );

  const handleProfitOverwriteToggle = useCallback(
    (data: UpdateValuesToggle[]) => {
      const params = {
        data,
        filters: { ...filters },
        query
      };
      dispatch(profitOverwrite(params))
        //@ts-ignore
        .then(() => {
          message.success('Ratio overwrite toggle success');
          afterUpdate();
        })
        .catch(() => {
          message.error('Failed to toggle ratio overwrite');
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, afterUpdate],
  );

  // console.log('NAMES', names)
  // console.log('LIST', list)

  function getRowClassName(record: ListType) {
    const getClass = (record: ListType) => {
      const defaultClassName = `${s.tableRow}`;
    }
  
    return `${getClass(record)}  ${record && record.mktRows && record.mktRows.map((item) => item.price && item.price.ext && item.price.ext.discountEndTs !== 0 ? `${s.XBOXProductTableColorPrice}` : '')}`;
  }

  // let marketsNames = markets && Object.values(markets)
  // console.log('mktNames', marketsNames)

    function GetColumns(): ColumnsType<ListType> {
      return [
        {
          title: 'ID/Name',
          dataIndex: 'pltProductId',
          key: 'pltProductId',
          width: 380,
          align: 'center',
          className: 'ellipsis',
          fixed: 'left',
          render: (_, record) => {
            return <div className="inner-rows__wrapper">
             <ColumnName afterUpdate={afterUpdate} mktRows={record.mktRows} name={record.pltName} id={record.pltProductId} link={record.pltUrl} theme={theme} content={record} />
             {record?.group && record?.group?.map((item, index) => <ColumnGroup key={index} pltId={item?.pltId} id={item?.productId} region={item?.region} url={item?.url} afterUpdate={afterUpdate}/>
             )}
             <ColumnCreateMapping name={record?.pltName} productId={record.pltProductId} region={record?.pltRegion} group={record?.group} afterUpdate={afterUpdate} />
             <CommentsSection pltId={record.pltId} productId={record.pltProductId} region={record.pltRegion} pltComment={record.pltComment} afterUpdate={afterUpdate} isLoading={isLoading} />
             <OrderKeysButton pltId={record.pltId} pltProductId={record.pltProductId} region={record.pltRegion} pltName={record.pltName} />
              </div>
          },
          shouldCellUpdate: (record, prevRecord) => !shallowEqual(record, prevRecord)

        },
        {
          title: 'Reg',
          dataIndex: 'pltRegion',
          key: 'pltRegion',
          align: 'center',
          width: 70,
          sorter: (a, b) => a.pltRegion.localeCompare(b.pltRegion),
          sortDirections: ['descend', 'ascend'],
          render: (_, record) => {
            return <div className="inner-rows__wrapper">
                  {(record.pltRegion).toUpperCase()}
                    </div>
          },
          shouldCellUpdate: (record, prevRecord) => !shallowEqual(record, prevRecord)
        },
        {
          title: 'XBOX Price',
          dataIndex: 'pltPrice',
          key: 'mktRows[0].price.ext.pltPrice',
          width: 120,
          align: 'center',
          className: 'inner-rows',
          sorter: (a: number | any, b: number | any) => a - b,
          sortDirections: ['descend', 'ascend'],
          render: (_, record) => {
            //@ts-ignore
            const rows = record.mktRows && record.mktRows[0]
            return <>
            {/* {rows.map((item, index) =>  */}
            <div className={s.innerRowContent}> 
                <XboxPrice
                priceInStock={rows?.price?.ext?.priceInStock}
                useStockPrice={rows?.price?.ext?.useStockPrice}
                pltPriceCur={rows?.price?.ext?.pltPriceCur}
                pltPrice={rows?.price?.ext?.pltPrice}
                pltCurrency={rows?.price?.ext?.pltCurrency}
                discount={rows?.price?.ext?.discount}
                defaultPrice={rows?.price?.ext?.pltDfltPrice}
                />
              </div>
            {/* )} */}
          </>
          },
          shouldCellUpdate: (record, prevRecord) => !shallowEqual(record, prevRecord)
        },
        {
          title: 'Stock',
          dataIndex: 'inventory.stockSize',
          key: 'inventory.stockSize',
          align: 'center',
          width: 80,
          sorter: (a, b) => +a.inventory.stockSize - +b.inventory.stockSize,
          sortDirections: ['descend', 'ascend'],
          render: (_, record) => record.inventory.stockSize,
          defaultSortOrder: 'descend',
          shouldCellUpdate: (record, prevRecord) => !shallowEqual(record, prevRecord)
        },
        {
          title: 'Rew',
          dataIndex: 'inventory.reward',
          key: 'inventory.reward',
          align: 'center',
          width: 70,
          sorter: (a, b) => +a.inventory.reward - +b.inventory.reward,
          sortDirections: ['descend', 'ascend'],
          render: (_, record) => record.inventory.reward,
          shouldCellUpdate: (record, prevRecord) => !shallowEqual(record, prevRecord)
        },
        {
          title: 'Discount Expire',
          dataIndex: 'mktRows[0].price.ext.discountEndTs',
          key: 'mktRows[0].price.ext.discountEndTs',
          width: 150,
          align: 'center',
          render: (_, record) => {
            //@ts-ignore
          const rows = record.mktRows && record.mktRows[0]
          return <ColumnDiscountExpire date={rows?.price?.ext?.discountEndTs} isSubscribed={rows?.price?.ext?.accFlags} discount={rows?.price?.ext?.discount} />
          },
          shouldCellUpdate: (record, prevRecord) => !shallowEqual(record, prevRecord)
        },
         { 
          title: 'Markets',
          dataIndex: 'rows.mktRows[0].mktId',
          key: 'rows.mktRows[0].mktId',
          width: 270,
          align: 'center',
          render: (_, record) => {
            const rows = record.mktRows && record.mktRows
            return <>
            {rows?.map((item, index) => { 
              const market = marketToString(item.mktId, markets)
            return <div key={index}>
              <ColumnMarkets mktId={item?.mktId} mktProductId={item?.mktProductId} marketLink={item?.mktUrl} market={market} enabled={item?.enabled} afterUpdate={afterUpdate} />
            </div>
            })}
            
            </>
            
        },
        shouldCellUpdate: (record, prevRecord) => !shallowEqual(record, prevRecord)
        },
        {
          title: 'Name / Product id',
          dataIndex: 'name',
          key: 'name',
          align: 'center',
          width: 250,
          render: (_, record) => {
            const rows = record.mktRows && record.mktRows
            const rowsLength = rows?.length
            return <>
              {/* @ts-ignore */}
              {rows?.map((item, index) => {
                const length = item?.mktName?.length
              return  <Row key={index} className={s.row54} justify={"center"}>
              <Col span={24}>
                <div>{item.mktName}</div>
              </Col>
              </Row>
            })}
          </>
          },
          shouldCellUpdate: (record, prevRecord) => !shallowEqual(record, prevRecord)
         },
          {
          title: 'Avail.',
          dataIndex: 'mktRows[0].inventory.ext.availAmount',
          key: 'mktRows[0].inventory.ext.availAmount',
          align: 'center',
          width: 55,
          render: (_, record) => {
            const rows = record.mktRows && record.mktRows
            return <>
            {/* @ts-ignore */}
            {rows.map((item, index) => 
            <Row key={index} className={s.row60}>
                <Col span={24}>
                  {item.inventory?.ext?.availAmount ? <span>{item.inventory?.ext?.availAmount}</span> : <span style={{opacity: '0.1'}}>n/d</span>}
                </Col>
              </Row>
              )}
            </>
  
          },
          shouldCellUpdate: (record, prevRecord) => !shallowEqual(record, prevRecord)
        },
        // =================================== ПОГРАНИЧНАЯ ПРОВЕРКА ====================
        {
          title: 'Statistics',
          width: 300,
          children: [
            {
              title: 'D',
              dataIndex: `inventory.${statisticsSorter}1`,
              key: 'inventory.sold1',
              align: 'center',
              width: 60,
              sorter: (a: number | any, b: number | any) => +a.inventory[(statisticsSorter as string) + 1] - +b.inventory[(statisticsSorter as string) + 1],
              sortDirections: ['descend', 'ascend'],
              render: (_, record) => {
                const rows = record.mktRows && record.mktRows
                return <div className={s.mb18}>
                <div><b>{record.inventory.sold1}</b></div>
                {/* @ts-ignore */}
                 {rows.map((item, index) =>
                 <React.Fragment key={index}>
                    <StatisticsTag
                      profit={item?.inventory?.profit1}
                      profitPct={item?.inventory?.profitPct1}
                      sold={item?.inventory?.sold1}
                      profitAvg={item?.inventory?.profitPK1}
                    />
                  </React.Fragment>
                 )}
                 </div>
              },
              shouldCellUpdate: (record, prevRecord) => !shallowEqual(record, prevRecord)
            },
            {
              title: 'D2',
              dataIndex: `inventory.${statisticsSorter}2`,
              key: 'inventory.sold2',
              align: 'center',
              width: 60,
              sorter: (a: number | any, b: number | any) => +a.inventory[(statisticsSorter as string) + 2] - +b.inventory[(statisticsSorter as string) + 2],
              sortDirections: ['descend', 'ascend'],
              render: (_, record) => {
                const rows = record.mktRows && record.mktRows
                return <div className={s.mb18}>
                <div><b>{record.inventory.sold2}</b></div>
                {/* @ts-ignore */}
                 {rows.map((item, index) =>
                 <React.Fragment key={index}>
                      <StatisticsTag
                        profit={item?.inventory?.profit2}
                        profitPct={item?.inventory?.profitPct2}
                        sold={item?.inventory?.sold2}
                        profitAvg={item?.inventory?.profitPK2}
                      />
                  </React.Fragment>
                 )}
                 </div>
              },
              shouldCellUpdate: (record, prevRecord) => !shallowEqual(record, prevRecord)
            },
            {
              title: 'W',
              dataIndex: `inventory.${statisticsSorter}7`,
              key: 'inventory.sold7',
              align: 'center',
              width: 60,
              sorter: (a: number | any, b: number | any) => +a.inventory[(statisticsSorter as string) + 7] - +b.inventory[(statisticsSorter as string) + 7],
              sortDirections: ['descend', 'ascend'],
              render: (_, record) => {
                const rows = record.mktRows && record.mktRows
                return <div className={s.mb18}>
                <div><b>{record.inventory.sold7}</b></div>
                 {rows.map((item, index) => 
                 <React.Fragment key={index}>
                    <StatisticsTag
                      profit={item?.inventory?.profit7}
                      profitPct={item?.inventory?.profitPct7}
                      sold={item?.inventory?.sold7}
                      profitAvg={item?.inventory?.profitPK7}
                    />
                    </React.Fragment>
                 )}
                 </div>
              },
              shouldCellUpdate: (record, prevRecord) => !shallowEqual(record, prevRecord)
            },
            {
              title: 'W2',
              dataIndex: `inventory.${statisticsSorter}14`,
              key: 'inventory.sold14',
              align: 'center',
              width: 60,
              sorter: (a: number | any, b: number | any) => +a.inventory[(statisticsSorter as string) + 14] - +b.inventory[(statisticsSorter as string) + 14],
              sortDirections: ['descend', 'ascend'],
              render: (_, record) => {
                const rows = record.mktRows && record.mktRows
                return <div className={s.mb18}>
                <div><b>{record.inventory.sold14}</b></div>
                 {rows.map((item, index) =>
                 <React.Fragment key={index}>
                    <StatisticsTag
                      profit={item?.inventory?.profit14}
                      profitPct={item?.inventory?.profitPct14}
                      sold={item?.inventory?.sold14}
                      profitAvg={item?.inventory?.profitPK14}
                    />
                    </React.Fragment>
                 )}
                 </div>
              },
              shouldCellUpdate: (record, prevRecord) => !shallowEqual(record, prevRecord)
            },
            {
              title: 'M',
              dataIndex: `inventory.${statisticsSorter}30`,
              key: 'inventory.sold30',
              align: 'center',
              width: 60,
              sorter: (a: number | any, b: number | any) => +a.inventory[(statisticsSorter as string) + 30] - +b.inventory[(statisticsSorter as string) + 30],
              sortDirections: ['descend', 'ascend'],
              render: (_, record) => {
                const rows = record.mktRows && record.mktRows
                return <div className={s.mb18}>
                <div><b>{record.inventory.sold30}</b></div>
                 {rows.map((item, index) => 
                 <React.Fragment key={index}>
                  <StatisticsTag
                    profit={item?.inventory?.profit30}
                    profitPct={item?.inventory?.profitPct30}
                    sold={item?.inventory?.sold30}
                    profitAvg={item?.inventory?.profitPK30}
                  />
                  </React.Fragment>
                 )}
                 </div>
              },
              shouldCellUpdate: (record, prevRecord) => !shallowEqual(record, prevRecord)
            },
            {
              title: 'Y',
              dataIndex: `inventory.${statisticsSorter}365`,
              key: 'inventory.sold365',
              align: 'center',
              width: 60,
              sorter: (a: number | any, b: number | any) => +a.inventory[(statisticsSorter as string) + 365] - +b.inventory[(statisticsSorter as string) + 365],
              sortDirections: ['descend', 'ascend'],
              render: (_, record) => {
                const rows = record.mktRows && record.mktRows
                return <div className={s.mb18}>
                <div><b>{record.inventory.sold365}</b></div>
                 {rows.map((item, index) =>
                 <React.Fragment key={index}>
                  <StatisticsTag
                    profit={item?.inventory?.profit365}
                    profitPct={item?.inventory?.profitPct365}
                    sold={item?.inventory?.sold365}
                    profitAvg={item?.inventory?.profitPK365}
                  />
                  </React.Fragment>
                 )}
                 </div>
              },
              shouldCellUpdate: (record, prevRecord) => !shallowEqual(record, prevRecord)
            },
            markets ? {
              title: 'Total',
              dataIndex: 'mktRows[0].inventory.sold',
              key: 'mktRows[0].inventory.sold',
              align: 'center',
              width: 60,
              render: (_, record) => {
                const rows = record.mktRows && record.mktRows
                return <>
                {rows.map((item, index) => 
                // <div className={s.borderedRow} key={index}>
                <Row key={index} className={s.row60}>
                    <Col span={24} >
                      {item.inventory?.sold ? <span>{item.inventory?.sold}</span> : <span style={{opacity: '0.1'}}>n/d</span>}
                      {/* </div>  */}
                    </Col>
                  </Row>
                 
                  )}
                </>
      
              },
              shouldCellUpdate: (record, prevRecord) => !shallowEqual(record, prevRecord)
            } : {},
          ]
        },
        // ОТНОСИТСЯ К SALE================================================================================================================================================
        { title: 'Sale',
          children: [
             {
              title: 'Profit',
              align: 'center',
              width: 60,
              children: [
                markets ? {
                  title: 'Price',
                  dataIndex: 'price',
                  key: 'price',
                  align: 'center',
                  width: 120,
                  render: (_, record) => {
                    const rows = record.mktRows && record.mktRows
                    return <>
                    {rows.map((item, index) => 
                    <Row key={index} className={s.row54}>
                    <Col span={24} >
                    <ColumnPriceG2A alert={''} priceForBuyers={item.price.buyerPrice} price={item.price.mktPrice} CURRENCY={CURRENCY} />
                      {item.price.alert &&
                      <Tooltip placement="topLeft" title={item.price.alert}>
                        <ExclamationCircleTwoTone twoToneColor="#eb2f96" className={s.fr}/>
                      </Tooltip>
                      }
                      </Col>
                      </Row>
                      
                    )}
                  </>
                  },
                  shouldCellUpdate: (record, prevRecord) => !shallowEqual(record, prevRecord)
                } : {},
                markets ? {
                  title: 'Cur Profit',
                  align: 'center',
                  width: 150,
                  sorter: (a, b) => {
                    const aProfits = Array.isArray(a.mktRows) ? a.mktRows.map(row => row.formula.currentProfit) : [];
                    const bProfits = Array.isArray(b.mktRows) ? b.mktRows.map(row => row.formula.currentProfit) : [];
                  
                    const aMaxProfit = Math.max(...aProfits, 0);
                    const bMaxProfit = Math.max(...bProfits, 0);
                  
                    return aMaxProfit - bMaxProfit;
                  },
                  sortDirections: ['descend', 'ascend'],
                  render: (_, record) => {
                    const rows = record.mktRows && record.mktRows
                    return <>
                    {rows.map((item, index) => 
                    <Row key={index} className={s.row60}>
                        <Col span={24}>
                          <ColumnCurrentProfit profit={item?.formula?.currentProfit} />
                        </Col>
                      </Row>
                    )}
                  </>
                  },
                  shouldCellUpdate: (record, prevRecord) => !shallowEqual(record, prevRecord)
                } : {},
                markets ? {
                  title: 'Ratio',
                  align: 'center',
                  width: 200,
                  render: (_, record) => {
                    const rows = record.mktRows && record.mktRows
                    return <>
                    {rows.map((item, index) => 
                    <Row key={index} className={s.row60}>
                        <Col span={24}>
                              <ColumnProfit
                                mktProductId={item.mktProductId}
                                mktId={item.mktId}
                                handleUpdateProfit={handleUpdateProfit}
                                isLoading={isLoading}
                                profitMin={item.price?.ext?.profitMin}
                                profit={item.price?.ext?.profitMax}
                                profitMinDef={item?.formula?.minProfitCalc}
                                profitDef={item?.formula?.maxProfitCalc}
                                isDefault={item?.formula?.isDefault}
                                handleProfitOverwriteToggle={handleProfitOverwriteToggle}
                              />
                              <br />
                              <ColumnPriceRatio
                                mktProductId={item.mktProductId}
                                mktId={item.mktId}
                                handleUpdateProfit={handleUpdateProfit}
                                isLoading={isLoading}
                                priceMin={item?.price?.ext?.mktPriceMin}
                                priceMax={item?.price?.ext?.mktPriceMax}
                                handleProfitOverwriteToggle={handleProfitOverwriteToggle}
                              />
                        </Col>
                      </Row>
                )}
                  </>
                  },
                  shouldCellUpdate: (record, prevRecord) => !shallowEqual(record, prevRecord)
                } : {}
              ]
          }, 
          {
            title: 'Competitors',
            width: 100,
            children: [
                markets ? {
                  title: 'Diff',
                  align: 'center',
                  width: 80,
                  render: (_, record) => {
                    const rows = record.mktRows && record.mktRows
                    return <div className="inner-rows__wrapper">
                        { rows.map((item, index) => {
                            let diff
                            if (item?.price?.mktPrice && item?.competitors) {
                              if (Math.sign(+item.competitors.min.mktPrice - +item.price.mktPrice) === -1) {
                                diff = <Tag color="red">{(+item.competitors.min.mktPrice - +item.price.mktPrice).toFixed(2)}</Tag>
                              } else {
                                diff = (+item.competitors.min.mktPrice - +item.price.mktPrice).toFixed(2)
                              }
                            } else diff = <span className={s.opacity}>none</span>
                            return (
                            <Row key={index} className={s.row60}>
                              <Col span={24}>
                                <span className="inner-row__content">{diff}</span>
                              </Col>
                            </Row>
                            )
                          }
                        )}
                  </div>
                  },
                  shouldCellUpdate: (record, prevRecord) => !shallowEqual(record, prevRecord)
                } : {},
                markets ? {
                  title: 'Min-Price',
                  align: 'center',
                  width: 180,
                  render: (_, record) => {
                    const rows = record.mktRows && record.mktRows
                    return <>
                    {rows.map((item, index) => {
                      return (
                      <Row key={index} className={s.row60}>
                          <Col span={24}>
                              <ColumnCompetitorsMin
                                competitors={item?.competitors}
                                showSpin={false}
                                CURRENCY={CURRENCY}
                                />
                          </Col>
                        </Row>
                      )
                        }
                        )}
                  </>
                  },
                  shouldCellUpdate: (record, prevRecord) => !shallowEqual(record, prevRecord)
                } : {}
            ]
        }
        // ОТНОСИТСЯ К SALE
      ]
    }, 

    // ОТНОСИТСЯ К SALE
        {
          title: 'Action',
              dataIndex: 'isActive',
              key: 'isActive',
              width: 220,
              align: 'center',
              className: 'inner-rows',
              render: (_, record) => {
                const rows = record.mktRows && record.mktRows
                return <div className="inner-rows__wrapper">
                  {rows.map((item, index) =>
                  <Row key={index} className={s.row60}>
                        <Col span={24}>
                          <span key={index} className="inner-row__content">
                            <ActionButtons
                              hasStock={item?.hasStock}
                              isActive={item?.isActive}
                              handleClick={(value) => handleChangeAction(item?.mktId, item?.mktProductId, value)}
                            />
                          </span>
                      </Col>
                    </Row>
                    )}
                </div>
              },
              shouldCellUpdate: (record, prevRecord) => !shallowEqual(record, prevRecord)
            },
        
      ]
    }
  

  const [columns, setColumns] = useState(GetColumns())
  
  const [Checkboxs, setCheckbox] = useState([]);


  const [AllCheckbox, setAllCheckbox] = useStorageState('product_table', {
      checkedList: Checkboxs,
      indeterminate: false,
      checkAll: true,
  });

  const onChangeCheckList = useCallback((checkedList) => {
    setAllCheckbox({
        checkedList,
        indeterminate: !!checkedList.length && checkedList.length < columns.length,
        checkAll: checkedList.length === columns.length,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [Checkboxs]);

useEffect(() => {
  let allCheckList = []
  if(list.length && !Checkboxs.length) {
      GetColumns().forEach((e) => {
          allCheckList.push( e[Object.keys(e)[1]])
          setCheckbox(allCheckList)
      })
  }
}, [list, AllCheckbox, Checkboxs.length])

useEffect(() => {
  setColumns(GetColumns())
}, [list])

useEffect(() => {
  if (AllCheckbox.checkAll) {

    const getAllTitles = (columns) => {
      const titles = [];

      const traverseColumns = (columns) => {
        columns.forEach((column) => {
            titles.push(column.title);
          
          if (column.children) {
            traverseColumns(column.children);
          }
        });
      };

      traverseColumns(columns);

      // console.log('titles', titles)
      return titles;
      
    };

    setAllCheckbox((prevState) => ({
      ...prevState,
      checkedList: getAllTitles(columns),
      indeterminate: false,
      checkAll: true,
    }));
  }
}, [setAllCheckbox, AllCheckbox.checkAll, columns]);

  const onChangeCheckAll = useCallback((e) => {
    setAllCheckbox({
        checkedList: (e.target.checked && columns.map(e => e.title)) || [],
        indeterminate: false,
        checkAll: e.target.checked,
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
}, [Checkboxs]);

interface DefWithNewProperties extends ColumnsType {
  children: any
}

const filterColumns = (columns: ColumnsType<ListType>) => {
  return columns.reduce((filteredColumns: string[], column: { title: string; children: string }) => {
    const filteredColumn = { ...column };

    if (AllCheckbox.checkedList.includes(column.title)) {
      if (filteredColumn.children) {
        filteredColumn.children = filterColumns(filteredColumn.children);
      }
      filteredColumns.push(filteredColumn);
    } else if (column.children) {
      const filteredChildren = filterColumns(column.children);
      if (filteredChildren.length > 0) {
        filteredColumn.children = filteredChildren;
        filteredColumns.push(filteredColumn);
      }
    }

    return filteredColumns;
  }, []);
};

const columnsFilter = filterColumns(GetColumns());


    useEffect(() => {
      onSelectChange([]);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list]);

  return (
    <Table
      virtual
      rowClassName={getRowClassName}
      bordered
      rowKey={(record) => `${record.mktRows.map((row) => [row.mktId, row.mktProductId])}`}
      scroll={{ y: 850, x: 1200 }}
      size="middle"
      dataSource={list}
      columns={columnsFilter}
      loading={isLoading}
      pagination={pagination}
      onChange={(a, b, c) => onChange(a, b, c)}
      title={() => <TableSettings
        onChangeCheckAll={onChangeCheckAll}
        allChecks={AllCheckbox}
        Checkboxs={AllCheckbox.checkedList}
        TableColumn={() => GetColumns()}
        setCheckbox={onChangeCheckList}
    />}
      rowSelection={{
        columnWidth: 48,
        fixed: true,
        selectedRowKeys,
        onChange: (selected) => onSelectChange(selected),
      }}
    />
  )
}

export default ProductsTable
