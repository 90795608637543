import React, { useCallback, useEffect, useState } from 'react';
import { Button, Tooltip, message } from 'antd'
import ShoppingCartOutlined from '@ant-design/icons/lib/icons/ShoppingCartOutlined';
import OrderKeysModal from '../OrderKeysModal';
import useBoolean from '../../../Utils/useBoolean';
import { useDispatch } from 'react-redux';
import { getQtyOnStock, orderGames } from '../../../reducers/dashboard';

interface OrderKeysButton {
  pltId: number,
  pltProductId: string,
  region: string,
  pltName: string
}

const initalValue = {
  countKey: 1,
};


const OrderKeysButton: React.FC<OrderKeysButton> = ({ pltId, pltProductId, region, pltName }) => {

  const { value: visible, setTrue: open, setFalse: close } = useBoolean(false);

  const dispatch = useDispatch();
  const [list, setList] = useState([]);

  const page = list && list[0]

  //@ts-ignore
  const pricingInfo = page?.prices && page?.prices[0]

  const amount = pricingInfo?.amount
  const price = pricingInfo?.value

  const [immediate, setImmediate] = useState(true);
  const [number, setNumber] = useState(initalValue.countKey);
  const [maxPrice, setMaxPrice] = useState(price);
  
  useEffect(() => {
    setMaxPrice(price)
  }, [price])

  useEffect(() => {
    if (number <= 2) {
      setImmediate(true)
    } else {
      setImmediate(false)
    }
  }, [number])
  

  const handleOnClick = () => {
    dispatch(getQtyOnStock(pltProductId, region))
    // @ts-ignore
      .then((res) => setList(res?.page))
    open() 
  }

  const onOk = () => {
    dispatch(
      orderGames(
        pltId,
        [`${pltProductId}:${region}`],
        number,
        typeof maxPrice === 'number' ? maxPrice.toFixed(2) : maxPrice,
        immediate,
      )
    )
    // @ts-ignore
      .then(() => {
        close();
        setNumber(initalValue.countKey);
        message.success(
          `Order has been created!`
        );
      })
      .catch((error: string) => {
        message.error(error);
      })
      .catch((error: string) => {
        message.error(error);
      });
  }

  

 

  return (
    <>
      <Button
        type="primary"
        size="small"
        icon={<ShoppingCartOutlined />}
        onClick={handleOnClick}
      />
      <OrderKeysModal
        onOk={onOk}
        close={close}
        visible={visible}
        pltId={pltId}
        pltProductId={pltProductId}
        region={region}
        pltName={pltName}
        amount={amount}
        price={maxPrice}
        number={number}
        setNumber={setNumber}
        setMaxPrice={setMaxPrice}
      />
      </>
  )
}

export default OrderKeysButton
