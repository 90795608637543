/* eslint-disable react/prop-types */
import React from 'react';
import { Filter } from '../../../../types/rootState';
import RangeInput from '../../../RangeInput';

interface PriceRangeFilter extends Filter {
  min: number,
  max: number,
  label?: string | null,
}

const XBOXPriceRange: React.FC<PriceRangeFilter> = ({ onChange, value, min, max, label = null }) => {
  return <RangeInput onChange={onChange} value={value} min={min} max={max} label={label || 'Xbox Price'} />;
}

export default XBOXPriceRange;
