import React from 'react'
import { Route, Routes } from "react-router-dom";
import RedirectPage from "../../pages/redirect";
import Dashboard from "../../pages/dashboard";
import DashboardSettings from "../../pages/dashboard-settings";
import Status from "../../pages/status";
import RedirectUserToMainPage from '../../components/Redirect/UserToMain';
import RedirectGuestToSignInPage from '../../components/Redirect/GuestToAuth';
import Fuse from '../../pages/fuse-settings';
import AutoMappingTable from '../../pages/auto-mapping';

function App() {

  return (
  <div className='App'>
    <Routes>
      <Route path="/redirect" element={<RedirectPage />} />
      <Route path="/sign-in" element={<RedirectUserToMainPage />} />
      <Route path="/" element={<RedirectGuestToSignInPage />}>
        <Route
          path="/"
          element={<Dashboard />}
        />
        <Route
          path="/dashboard"
          element={<Dashboard />}
        />
        <Route path="/dashboard-settings" element={<DashboardSettings />} />
        <Route path="/status" element={<Status />} />
        <Route path="/fuse-settings" element={<Fuse />} />
        <Route path="/auto-mapping" element={<AutoMappingTable />} />
      </Route>
    </Routes>
  </div>
  )
}

export default App;
