import React from 'react';
import AuthLayout from '../../components/AuthLayout';
import SingInForm from '../../components/SignInForm';
import s from './styles.module.scss';



function SignIn() {
  return (
    <AuthLayout className={s.authLayout}>
      <div className={s.auth}>
        <SingInForm />
      </div>
    </AuthLayout>
  );
}

export default SignIn;
