import React, { useEffect } from 'react';
import qs from 'qs';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { authGetTokens } from '../../reducers/auth';
import TokenService from '../../services/TokenService';

function RedirectPage() {
  const location = useLocation();
  const query = qs.parse(location.search.replace(/^\?/, ''));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log('query', query)

  useEffect(() => {
    if (query.code) {
      TokenService.removeToken();
      dispatch(authGetTokens(query.code))
      //@ts-ignore
        .then((data) => {
          TokenService.setTokens(data);
          console.log('TOKENDATA ====>', data)
          navigate('/', { replace: true })
        })
        .catch((e: string) => {
          console.log('..........', e);
          navigate('/sign-in', { replace: true })
        });
    }
  }, [dispatch, navigate, query.code]);

  if (!query.code) {
    return <Navigate to="/sign-in" />;
  }

  return <div>Wait for save token</div>;
}

export default RedirectPage;
