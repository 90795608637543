import React, { useCallback } from 'react';
import { Select, Tooltip } from 'antd';
import { debounce } from 'lodash';
import { lookupGamivo, lookupKinguin } from '../../../../reducers/dashboard';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import clearSpecialCharacters from '../../../../Utils/clearSpecialCharacters';
import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined';
import { CreateMapping } from '../../../../types/rootState';

const { Option } = Select;

interface LookupState {
  dashboard: {
    lookKinguin: any
  }
}


const ProductCreateSearch: React.FC<CreateMapping> = ({setMktProductId }) => {

  const dispatch = useDispatch()
  const lookup = useSelector((state: LookupState) => state?.dashboard?.lookKinguin, shallowEqual);

  const onSearch = useCallback(
    (() => {
      const debouncedLookup = debounce((value) => {
        dispatch(lookupKinguin(value))
      }, 700);

      return (query: string) => {
        const cleared = clearSpecialCharacters(query);
        if (cleared.length >= 3) {
          debouncedLookup(cleared);
        }
      };
    })(),
    [],
  );

  const handleChange = (e: number) => {
    setMktProductId(e)
  };


return (
 
  <Select
  showSearch
  showArrow
  placeholder="Input search query"
  defaultActiveFirstOption={false}
  filterOption={false}
  onSearch={onSearch}
  onChange={handleChange}
  notFoundContent={
    !lookup.isLoading ? (
      <p>Empty list</p>
    ) : (
      <p>
        <LoadingOutlined />
      </p>
    )
  }
  loading={lookup.isLoading}
>
  
  {lookup && lookup?.payload?.map((item: any) =>
  
  
      <Option value={item.id} title={item.slug} key={item.id}>
        {item.name.length > 60 ? 
        <Tooltip placement="bottom"  title={item.name}>
      {item.name}
      </Tooltip> :
      <>{item.name}</>
        }
      </Option>
   
  )}
</Select>
   
);

};

export default ProductCreateSearch


