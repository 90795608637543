import React, { useCallback } from 'react';
import { Input } from 'antd';
import PercentageOutlined from '@ant-design/icons/lib/icons/PercentageOutlined';

interface ChangePriceRatio {
  value: number[],
  handleChange: any,
  className?: string,
  priceMin: number,
  priceMax: number
};

const ChangePriceRatio: React.FC<ChangePriceRatio> = ({ value, handleChange, className, priceMin, priceMax}) => {
  const onChange = useCallback(
    (v: any, index: number) => {
      const newValue = [...value];
      newValue[index] = v;
      handleChange(newValue);
    },
    [value, handleChange],
  );

  return (
    <div className={className}>
      <div style={{ display: 'flex' }}>
        <Input
          size="small"
          style={{ display: 'flex', width: '120px' }}
          //@ts-ignore
          placeholder={priceMin}
          type="number"
          addonAfter={'€'}
          //@ts-ignore
          value={value[0] || null}
          onChange={(e) => onChange(+e.target.value, 0)}
        />
        <span className="mr-small ml-small">/</span>
        <Input
          size="small"
          style={{ display: 'flex', width: '120px' }}
          //@ts-ignore
          placeholder={priceMax}
          type="number"
          addonAfter={'€'}
          //@ts-ignore
          value={value[1] || null}
          onChange={(e) => onChange(+e.target.value, 1)}
        />
      </div>
    </div>
  );
}

export default ChangePriceRatio;
