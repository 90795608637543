import React from 'react';
import { Spin } from 'antd';
import cx from '../../Utils/cx';
import s from './styles.module.scss';

interface LoadingProps {
  children: any,
  loading: string | boolean,
}

const LoadingBlock: React.FC<LoadingProps> = ({ children, loading }) => {
  return (
    <div className={s.LoadingBlock}>
    <div className={cx(s.noMinHeight, s.className)}>
      <div className={cx(s.content, s.loading, s.contentClassName)}>
        {children}
      </div>
      {loading && (
        <div className={s.spinContainer}>
          <Spin className={s.spin} />
        </div>
      )}
    </div>
    </div>
  );
}

export default LoadingBlock;
