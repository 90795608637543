import React, { useCallback } from 'react';
import { Select } from 'antd';
import { debounce } from 'lodash';
import { lookupG2A } from '../../../../reducers/dashboard';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import clearSpecialCharacters from '../../../../Utils/clearSpecialCharacters';
import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined';
import { CreateMapping } from '../../../../types/rootState';

const { Option } = Select;

interface LookupState {
  dashboard: {
    lookG2A: any
  }
}


const ProductCreateG2A: React.FC<CreateMapping> = ({setMktProductId}) => {

  const dispatch = useDispatch()

  const lookup = useSelector((state: LookupState) => state?.dashboard?.lookG2A, shallowEqual);

  // console.log('LOOKUP', lookup)

  const onSearch = useCallback(
    (() => {
      const debouncedLookup = debounce((value) => {
        dispatch(lookupG2A(value))
      }, 700);

      return (query: string) => {
        const cleared = clearSpecialCharacters(query);
        if (cleared.length >= 3) {
          debouncedLookup(cleared);
        }
      };
    })(),
    [],
  );

  const handleChange = (e: number) => {
    setMktProductId(e)
  };

return (
 
  <Select
  showSearch
  showArrow
  placeholder="Input search query"
  defaultActiveFirstOption={false}
  filterOption={false}
  onSearch={onSearch}
  onChange={handleChange}
  notFoundContent={
    !lookup.isLoading ? (
      <p>Empty list</p>
    ) : (
      <p>
        <LoadingOutlined />
      </p>
    )
  }
  loading={lookup.isLoading}
>
  {lookup && lookup?.payload?.map((item: any, index: number) => <Option value={item.id} key={index}>{item.name}</Option>)}
</Select>
   
);

};

export default ProductCreateG2A


