import React from 'react';
import { Input } from 'antd';
import PercentageOutlined from '@ant-design/icons/lib/icons/PercentageOutlined';



const ProfitInput = (props, _ref) => {
  const { overwriteProfit, value, handleChangeProfit } = props;

  // console.log('PROFITVALUE', value)

  const handleChange = (e) => {
    handleChangeProfit(e.target.value)
  }

  return (
    <Input
      type="number"
      style={{ width: '100%' }}
      value={value}
      readOnly={!overwriteProfit}
      disabled={!overwriteProfit}
      placeholder="default"
      onChange={handleChange}
      addonAfter={<PercentageOutlined />}
    />
  );
}

export default React.forwardRef(ProfitInput);
