import React from 'react';
import PrintPercent from '../Print/PrintPercent';

interface MinCurrentProfit {
  profitMin?: number,
  currentProfit?: number,
  className?: string,
  isDefault: boolean
};


const MinCurrentProfit: React.FC<MinCurrentProfit> = ({ profitMin, currentProfit, className, isDefault }) => {
  return (
    <span className={className}>
      <PrintPercent isDefault={isDefault} percent={profitMin} />
      <span className="ml-small mr-small">/</span>
      <PrintPercent isDefault={isDefault} percent={currentProfit} />
    </span>
  );
}

export default MinCurrentProfit;
