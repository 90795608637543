import React, { useRef, useState } from 'react';
import { Button, Drawer, message, Upload } from 'antd';
import UploadOutlined from '@ant-design/icons/UploadOutlined';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';
import fec from "front-end-common";
import { uploadKeys } from '../../../reducers/dashboard';
import { RcFile } from 'antd/es/upload';

const useBoolean = fec.hooks.useBoolean;

interface UploadKeys {
  className: string
  afterSuccess: any
}

interface UploadState {
  dashboard: {
    loadKeys: {
      payload: any
      error: string
      isLoading: boolean
    }
  }
}

const UploadKeys: React.FC<UploadKeys> = ({ className, afterSuccess }) => {
  const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);
  const { current: form } = useRef(new FormData());
  const dispatch = useDispatch();
  const { payload, error, isLoading } = useSelector((state: UploadState) => state.dashboard.loadKeys, shallowEqual);
  const [content, setContent] = useState('')

  const handleSubmit = () => {

    dispatch(uploadKeys(content))
    //@ts-ignore
      .then((res: string) => {
        console.log('res', res)
        if (!error) {
          message.success(res);
          afterSuccess();
          close()
        } else {
          if (error.includes('duplicate key value')) message.error(`Failed to upload: Keys already exist!`)
          else message.error(`Failed to upload: ${error}`)
        }
          
      })
      .catch((error: string) => {
        if (error.includes('duplicate key value')) message.error(`Failed to upload: Keys already exist!`)
        else message.error(`Failed to upload: ${error}`)
      })
  };

  const beforeUpload = async (file: RcFile) => {
    form.delete('file');
  
    const fileContent = await file.text();

    setContent(fileContent)
  
    return false;
  };

  const onRemove = () => {
    form.delete('file');
  };

  return (<div className={styles.uploadKeys}>
    <Button size="middle" type="primary" onClick={open} className={className} icon={<UploadOutlined />}>
      Upload Keys
    </Button>
    <Drawer visible={isOpen} title="Upload Keys" width={600} closable placement="right" onClose={close}>
      <div className={styles.uploadKeys__line}>
        <b>Keys</b>
        <Upload accept=".csv"
                onRemove={onRemove}
                beforeUpload={beforeUpload}
        >
          <Button icon={<UploadOutlined />}>Select File</Button>
        </Upload>
      </div>
      <div className={styles.uploadKeys__line}>
        <Button type="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </div>
    </Drawer>
  </div>);
}

export default UploadKeys;
