import { Popconfirm, Popover } from 'antd';
import React from 'react';
import LockOutlined from '@ant-design/icons/lib/icons/LockOutlined';
import UnlockOutlined from '@ant-design/icons/lib/icons/UnlockOutlined';
import QuestionCircleOutlined from '@ant-design/icons/lib/icons/QuestionCircleOutlined';


interface ChangeStatus {
  value?: boolean,
  id: string,
  handleLockStatusToggle: any,
  mktId: number
};

const ChangeStatusLock: React.FC<ChangeStatus> = ({ value, id, mktId, handleLockStatusToggle }) => {
  return (
    <Popconfirm
      title={
        <span>
          Do you really want to <b>{value ? 'lock' : 'unlock'}</b> a status change?
        </span>
      }
      icon={<QuestionCircleOutlined />}
      okText="Yes"
      cancelText="No"
      onConfirm={() => handleLockStatusToggle(mktId, id, !value)}
    >
      <Popover
        content={
          <span>
            Change status <b>{value ? 'unlocked' : 'locked'}</b>
          </span>
        }
      >
        {value ? (
          <UnlockOutlined style={{fontSize: '10px'}}/>
        
        ) : (
          <LockOutlined style={{fontSize: '10px', color: 'red'}}/>
        )
        }
      </Popover>
    </Popconfirm>
  );
}

export default React.memo(ChangeStatusLock);
