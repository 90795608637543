import React from 'react';
import { Popover, Tooltip } from 'antd';
import cx from '../../../Utils/cx';
import PrintAmount from '../../../components/Print/PrintAmount';
import { ExclamationCircleTwoTone } from '@ant-design/icons';
import s from './styles.module.scss'

interface ColumnPrice {
  price: number,
  CURRENCY: string,
  priceForBuyers: number,
  alert: string
}

const PrintColumn: React.FC<ColumnPrice> = ({ price, priceForBuyers, CURRENCY, alert }) => {
  const isGrey = !priceForBuyers;
  
  return (
    <div className={cx(s.withTwoLine, isGrey ? s.grey : '')}>
      {alert && (
        <Tooltip placement="left" title={alert}>
          <ExclamationCircleTwoTone twoToneColor="#eb2f96" style={{ float: 'right', marginTop: '5px' }} />
        </Tooltip>
      )}
      <PrintAmount currency={CURRENCY} amount={price * 100} className={''} classNameAmount={''} colorize={false} withZero={false} />
      <small>
        <PrintAmount currency={CURRENCY} amount={priceForBuyers * 100} className={''} classNameAmount={''} colorize={false} withZero={false} />
      </small>
    </div>
  );
};

const ColumnPrice: React.FC<ColumnPrice> = ({ price, priceForBuyers, CURRENCY, alert }) => {
  return priceForBuyers ? (
    <PrintColumn price={price} priceForBuyers={priceForBuyers} CURRENCY={CURRENCY} alert={alert} />
  ) : (
    <Popover placement="right" content="Not synced with G2A">
      <PrintColumn price={price} priceForBuyers={priceForBuyers} CURRENCY={CURRENCY} alert={alert} />
    </Popover>
  );
};


export default React.memo(ColumnPrice);
