import { Tooltip, Spin } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import PrintAmount from '../../../components/Print/PrintAmount';
import s from './styles.module.scss'

interface ColumnCompetitorsMin {
  competitors?: {
    diff: number,
    gotAt: number,
    min: {
      buyerPrice: number,
      mktPrice: number,
      offerInfo: {
        name: string,
        positiveVotes: number,
        rating: number,
        sellerId: number,
        transactions: number
      }
    }
  },
  CURRENCY: string,
  showSpin: boolean,
};

const ColumnCompetitorsMin: React.FC<ColumnCompetitorsMin> = ({ competitors, CURRENCY, showSpin }) => {
  if (showSpin)
    return (
      <div className="text-center">
        <Spin />
      </div>
    );

  if (!competitors || !competitors.min.offerInfo.name || !competitors.min.mktPrice) return <div className={s.none}>none</div>;

  return (
    <Tooltip title={competitors.min.offerInfo.name}>
    <div className={s.withTwoLine}>
      <PrintAmount className="block" currency={CURRENCY} amount={competitors.min.mktPrice * 100} />
      {/* <br /> */}
      <small>
        <PrintAmount className="block" currency={CURRENCY} amount={competitors.min.buyerPrice * 100} />
      </small>
      
        {/* <small className={s.competitorName}>
          <b>{competitors.min.offerInfo.name}</b>
        </small> */}
      
    </div>
    </Tooltip>
  );
}

export default React.memo(ColumnCompetitorsMin);
