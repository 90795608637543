const splitArray = (arr) => {
  const chunkSize = 2;
  let newArr = []
  for (let element of arr) {
    for (let i = 0; i < element.length; i += chunkSize) {
      const chunk = element.slice(i, i + chunkSize);
      let obj = { mktId: +chunk[0], mktProductId: chunk[1] }
      newArr.push(obj)
    }
  }
  return newArr
}

export default splitArray
