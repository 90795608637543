import ApiService from './ApiService';
import { arena, cargo, core, gamivo, ggsel, jukebox, kinguin, merchant } from './Constants';
import { v4 as uuidv4 } from 'uuid';

function preparedXboxLookup(arr, region) {
  const newArr = [];

  // eslint-disable-next-line camelcase
  arr.forEach(({ app_id, app_name, app_type, regions }) => {
    newArr.push({
      app_id,
      app_name,
      app_type,
      price_initial: regions[region].base_p_eur || null,
      price_discount: regions[region].base_dp_eur || null,
      discount: regions[region].base_d || null,
      gld: regions[region].gld || false,
      ea: regions[region].ea || false,
      gamepass: regions[region].gamepass || false,
      eod: regions[region].eod || false,
    });
  });
  return newArr;
}

function newIdToObject(id) {
  const [productId, region] = id.split(':');

  return {
    productId,
    region,
  };
}

export function prepareChangeItem(params) {
  // params = data, query, filters
  let url = '';

  const { filters, query } = params;

  const obj = {
    filters,
    query,
  };

  if (obj.query) {
    url += `&query=${encodeURIComponent(obj.query)}`;
  }

  if (obj && obj.filters) {
    Object.keys(obj.filters).forEach((item) => {
      const value = obj.filters[item];
      if (value) {
        if (Array.isArray(value)) {
          value.forEach((element) => {
            url += `&${item}[]=${encodeURIComponent(element)}`;
          });
          return;
        }

        url += `&${item}=${encodeURIComponent(value)}`;
      }
    });
  }

  return url;
}

const DashboardService = {
  fetchOrderedList({ filters, ...params }) {

    // console.log('FETCHPARAMS', params)
    // console.log('FETCHFILTERS', filters)

    const updatedParams = { ...params };

    if (filters && Object.keys(filters).length) {
      for (let key in filters) {
        updatedParams[key] = filters[key];
      }
    }

    return ApiService.get(`${core}/offers`, updatedParams);
  },

  // test
  fetchProducts() {
    return ApiService.get(`${core}/products`);
  },
  // test

  fetchMarketsList() {
    return ApiService.get(`${core}/markets`);
  },

  fetchComments(params) {

    const { pltId, productId, region } = params

    return ApiService.get(`${core}/product/${pltId}/${productId}/${region}/comments`)
  },

  addComment(params, comment) {
    const { pltId, productId, region } = params;

    const url = `${core}/product/${pltId}/${productId}/${region}/comments`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain',
      },
      body: comment,
    };

    // Get the authorization token from the ApiService
    const auth = ApiService.ts.getAuth();

    // Set the authorization header
    options.headers['Authorization'] = auth;

    // Make the request using fetch
    return fetch(ApiService.getApiLink(url), options);
  },


  setAction(params) {
    const { mktId, mktProductId, data } = params;

    const query = prepareChangeItem(params);

    return ApiService.patch(
      // `${core}/mapping`, [{ "mktId": mktId, "mktProductId": mktProductId, "isActive": value, "hasStock": stock }],
      `${core}/mapping`, [{ ...data, "mktId": mktId, "mktProductId": mktProductId }],
    );
  },

  setLockProduct(params) {
    const { mktId, mktProductId, value } = params;
    return ApiService.patch(
      `${core}/mapping`, [{ "mktId": mktId, "mktProductId": mktProductId, "enabled": value }],
    );
  },

  xboxUpdateProfit(params) {

    const { data } = params

    const query = prepareChangeItem(params);

    return ApiService.patch(`${core}/mapping`, data);
  },

  profitOverwriteToggle(params) {
    const { data } = params

    const query = prepareChangeItem(params);

    return ApiService.patch(`${core}/mapping`, [...data]);
  },

  groupUpdate(data) {
    return ApiService.patch(`${core}/mapping`, data);
  },

  updatePlatformProductId(data) {

    return ApiService.patch(`${core}/mapping`, data);
  },

  applyStatusToProducts(params) {
    const query = prepareChangeItem(params);

    const requestData = params.status

    return ApiService.patch(`${core}/mapping?multi=true${(query && `${query}`) || ''}`, requestData);
  },

  changeProfit(params) {
    const query = prepareChangeItem(params);

    const requestData = params.d;

    return ApiService.patch(`${core}/mapping?multi=true${(query && `${query}`) || ''}`, requestData);
  },

  setDefaultProfit(params) {
    const query = prepareChangeItem(params);
    const requestData = [{ setDefaultProfit: params.setDefaultProfit }];

    return ApiService.patch(`${core}/mapping?multi=true${(query && `${query}`) || ''}`, requestData);
  },

  digiLookup(q) {
    return ApiService.get(`${ggsel}/products/ggsel`, { q })
  },

  platiMarketLookup(q) {
    return ApiService.get(`${ggsel}/products/plati`, { q })
  },

  //  /digiseller/api/v1/products/plati

  g2aLookup(q) {
    return ApiService.get(`${arena}/xbox/products`, { q })
  },

  gamivoLookup(q) {
    return ApiService.get(`${gamivo}/xbox/products`, { q })
  },

  kinguinLookup(q) {
    return ApiService.get(`${kinguin}`, { q })
  },

  gameLookup(q) {
    return ApiService.get(`${jukebox}/i/${q}`)
  },

  // xboxLookup(q) {
  //   return ApiService.get(`${gamivo}/xbox/products`, { q })
  // },

  xboxLookup(query, region) {
    const preparedQuery = query.split(/\s+/).join(',');
    return ApiService.get(`${jukebox}/s`, {
      q: preparedQuery,
      r: region.toLowerCase(),
    }).then((data) => preparedXboxLookup(data, region));
  },

  fetchRegionsXbox() {
    return ApiService.get(`/api/v3/regions`);
  },

  createProduct(data) {
    return ApiService.post(`${core}/mapping`, [{ ...data, pltId: 2 }])
  },

  fetchSettings() {
    // data
    return ApiService.get(`/api/v3/configs`);

    // return new Promise((resolve) => {
    //   setTimeout(resolve, 1000, mockSettings);
    // });
  },

  fetchSettingsSchema() {
    return ApiService.get(`/api/v3/configs/schema`)
  },
  fetchSettingsUISchema() {
    return ApiService.get(`/api/v3/configs/uischema`)
  },
  updateSettings(data) {
    return ApiService.put(`/api/v3/configs`, data);
  },

  fetchStatus() {
    return ApiService.get(`/api/v3/status`);
  },

  postGroupMapping(data) {
    return ApiService.post(`/api/v3/product/group`, { ...data, pltId: 2 })
  },

  deleteGroupMapping(data) {
    return ApiService.delete(`/api/v3/product/group`, { ...data, pltId: 2 })
  },

  deleteMapping(data) {
    return ApiService.delete(`/api/v3/mapping`, [{ ...data, pltId: 2 }])
  },

  getQuantityOnStock(productId, region) {
    return ApiService.get(`${cargo}/xbox/prices?customer_id=24&offset=0&limit=10&query=${productId}&region=${region}`)
  },

  // orderId пока оставляем пустым до дальнейших распоряжений
  orderGames(pltId, ids, count, maxPrice, immediate) {
    const items = ids.map((id) => ({
      ...newIdToObject(id),
      qty: count,
      maxPrice,
      pltId: pltId,
    }));

    const q = `&immediate=${immediate}`;

    return ApiService.post(
      `${merchant}/order?customer_id=24${q}`,
      {
        reqId: uuidv4(),
        ext: "",
        products: items
      }
    );
  },

  async keysUpload(data) {
    console.log('data', data)
    const options = {
      headers: {
        // 'Accept': 'application/json, text/plain, */*',
        // 'Content-type': 'text/plain',
      },
      body: data,
    }

    return ApiService.call('/directsale/api/v2/mgr/stock/fromcsv', 'POST', options);
  },

};

export default DashboardService
