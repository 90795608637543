import React from 'react';
import { Radio } from 'antd';
import { Filter } from '../../../../types/rootState';
import { MonetizationOnTwoTone } from '@mui/icons-material';

const CustomDefaultProfitButton:React.FC<Filter> = ({ onChange, value = '', className }) => {
  return (
    <>
      <div>
      <MonetizationOnTwoTone style={{fontSize: '12px', marginRight: '4px', color: 'rgb(24, 144, 255)'}} />
        <b>Profit</b>
      </div>
      <div className={className}>
        <Radio.Group onChange={onChange} value={value}>
          <Radio.Button value="custom">Custom</Radio.Button>
          <Radio.Button value={null}>All</Radio.Button>
          <Radio.Button value="default">Default</Radio.Button>
        </Radio.Group>
      </div>
    </>
  );
}

export default CustomDefaultProfitButton;
