import TokenService from './TokenService';
import ApiService from './ApiService';

const CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID
const AUTH_URL = process.env.REACT_APP_AUTH_URL
const REDIRECT_URL = process.env.REACT_APP_AUTH_REDIRECT_URL


const AuthService = {
  signInAmazon(idToken, accessToken) {
    return new Promise((resolve) => {
      TokenService.setToken(idToken, accessToken);
      resolve();
    });
  },
  getTokens(code) {
    const url = AUTH_URL + '/token';

    const data = {
      code,
      grant_type: 'authorization_code',
      client_id: CLIENT_ID,
      redirect_uri: REDIRECT_URL,
      code_verifier: TokenService.getChallenge(),
    };

    return ApiService.postToCognito(url, data);
  },
};

export default AuthService
