import { checkMoment, isMoment, unixToMoment } from './date';
import dayjs from 'dayjs';
import objectSupport from "dayjs/plugin/objectSupport";
dayjs.extend(objectSupport);

export const MIN_SELL_COUNT = 0;
export const MAX_SELL_COUNT = 1000000;

export const MIN_CURRENT_XBOX_PRICE = 0;
export const MAX_CURRENT_XBOX_PRICE = 100000;

export const MIN_PROFIT = -10000;
export const MAX_PROFIT = 10000;

function checkNumber(value, maxValue) {
  if (value !== maxValue) {
    if (value) {
      return value;
    }
    if (value === 0) {
      return '0';
    }
  }
  return null;
}

export function formDataToUrlData(values) {
  const {
    discountDate,
    currentProfit,
    xboxPrice,
    xboxPriceCur,
    sellCount,
    settableProfit,
    withoutCompetitors,
    withDiscount,
    updatingStatus,
    onlyEmpty,
    digiStatus,
    steamDiscount,
    discount,
    profit,
    errors,
    status,
    active,
    pltRegion,
    enabled,
    onlyStockTrade,
    price_use_stock_price,
    xboxAvailAmount,
    mktId,
    profitMax,
    stock,
    discountSubscr
  } = values;

  const newFilters = {};
  // console.log('discdate', discountDate[0].startOf('second').unix())

  newFilters.sell_count_min = sellCount ? checkNumber(sellCount[0], MIN_SELL_COUNT) : null;
  newFilters.sell_count_max = sellCount ? checkNumber(sellCount[1], MAX_SELL_COUNT) : null;
  newFilters['price.mktPrice.min'] = xboxPrice ? checkNumber(xboxPrice[0], MIN_CURRENT_XBOX_PRICE) : null;
  newFilters['price.mktPrice.max'] = xboxPrice ? checkNumber(xboxPrice[1], MAX_CURRENT_XBOX_PRICE) : null;
  newFilters.xbox_price_cur = xboxPriceCur || null;
  newFilters['formula.currentProfit.min'] =
    (currentProfit &&
      currentProfit[0] !== MIN_PROFIT &&
      (+currentProfit[0] === 0 ? '0' : (+currentProfit[0] / 100).toFixed(3))) ||
    null;
  newFilters['formula.currentProfit.max'] =
    (currentProfit &&
      currentProfit[1] !== MAX_PROFIT &&
      (+currentProfit[1] === 0 ? '0' : (+currentProfit[1] / 100).toFixed(3))) ||
    null;
  newFilters.settable_profit_min = (settableProfit && settableProfit[0] !== MIN_PROFIT && settableProfit[0]) || null;
  newFilters['price.xbox_avail_amount_from'] =
    (xboxAvailAmount && xboxAvailAmount[0] !== MIN_SELL_COUNT && xboxAvailAmount[0]) || null;
  newFilters['price.xbox_avail_amount_to'] =
    (xboxAvailAmount && xboxAvailAmount[1] !== MAX_SELL_COUNT && xboxAvailAmount[1]) || null;
  newFilters.settable_profit_max = (settableProfit && settableProfit[1] !== MAX_PROFIT && settableProfit[1]) || null;
  newFilters.competitors = withoutCompetitors || null;
  newFilters.with_discount = withDiscount || null;
  newFilters.steam_discount = steamDiscount || null;
  newFilters['price.ext.discountEndTs.min'] =
    // discountDate && isMoment(discountDate[0]) ? discountDate && discountDate[0].startOf('second').unix() : null;
    discountDate && discountDate[0] && discountDate[0].startOf('second').unix() * 1000 || null
  newFilters['price.ext.discountEndTs.max'] =
    // discountDate && isMoment(discountDate[1]) ? discountDate && discountDate[1].endOf('second').unix() : null;
    discountDate && discountDate[1] && discountDate[1].endOf('second').unix() * 1000 || null
  newFilters.digi_updating = updatingStatus;
  newFilters.only_empty = onlyEmpty;
  newFilters.digi_status = (digiStatus && digiStatus[0] && digiStatus) * 1000 || null;
  newFilters.errors = errors;
  newFilters.only_stock_trade = onlyStockTrade;
  newFilters.isActive = status;
  newFilters.hasStock = active;
  newFilters.profit = profit;
  newFilters.pltRegion = pltRegion
  newFilters.mktId = mktId
  newFilters.enabled = enabled;
  newFilters.discount = discount;
  newFilters.stock = stock;
  newFilters['price.use_stock_price'] = price_use_stock_price;
  newFilters['price.ext.profitMax'] = profitMax;
  newFilters['price.ext.accFlags'] = discountSubscr;

  return { filters: newFilters, query: values.query ? values.query : null };
}

export function urlFiltersToFormData(values, query, defaultFormValues) {
  const newFilters = { ...defaultFormValues };

  if (query) {
    newFilters.query = query;
  }
  if (values.sell_count_min) {
    newFilters.sellCount[0] = +values.sell_count_min;
  }
  if (values.sell_count_max) {
    newFilters.sellCount[1] = +values.sell_count_max;
  }
  if (values['price.mktPrice.min']) {
    newFilters.xboxPrice[0] = +values['price.mktPrice.min'];
  }
  if (values['price.mktPrice.max']) {
    newFilters.xboxPrice[1] = +values['price.mktPrice.max'];
  }
  if (values.xbox_price_cur) {
    newFilters.xboxPriceCur = values.xbox_price_cur;
  }
  if (values['formula.currentProfit.min']) {
    newFilters.currentProfit[0] = (+values['formula.currentProfit.min'] * 100).toFixed(3);
  }
  if (values['formula.currentProfit.max']) {
    newFilters.currentProfit[1] = (+values['formula.currentProfit.max'] * 100).toFixed(3);
  }
  if (values.settable_profit_min) {
    newFilters.settableProfit[0] = +values.settable_profit_min;
  }
  if (values.settable_profit_max) {
    newFilters.settableProfit[1] = +values.settable_profit_max;
  }
  if (values.competitors) {
    newFilters.withoutCompetitors = values.competitors;
  }
  if (values.with_discount) {
    newFilters.withDiscount = values.with_discount;
  }
  if (values['price.xbox_avail_amount_from']) {
    newFilters.xboxAvailAmount[0] = values['price.xbox_avail_amount_from'];
  }
  if (values['price.xbox_avail_amount_to']) {
    newFilters.xboxAvailAmount[1] = values['price.xbox_avail_amount_to'];
  }
  if (values.steam_discount) {
    newFilters.steamDiscount = values.steam_discount;
  }

  if (values['price.ext.discountEndTs.min']) {
    newFilters.discountDate[0] = unixToMoment(+values['price.ext.discountEndTs.min']);
  }
  if (values['price.ext.discountEndTs.max']) {
    newFilters.discountDate[1] = unixToMoment(+values['price.ext.discountEndTs.max']);
  }

  if (values.digi_updating) {
    newFilters.updatingStatus = values.digi_updating;
  }
  if (values.only_empty) {
    newFilters.onlyEmpty = values.only_empty;
  }
  if (values.digi_status) {
    newFilters.digiStatus = values.digi_status;
  }

  if (values.isActive) {
    newFilters.status = values.isActive;
  }
  if (values.hasStock) {
    newFilters.active = values.hasStock;
  }
  if (values.errors) {
    newFilters.errors = values.errors;
  }
  if (values.only_stock_trade) {
    newFilters.onlyStockTrade = values.only_stock_trade;
  }
  if (values.profit) {
    newFilters.profit = values.profit;
  }
  if (values.pltRegion) {
    newFilters.pltRegion = values.pltRegion;
  }
  if (values.mktId) {
    newFilters.mktId = values.mktId;
  }
  if (values.discount) {
    newFilters.discount = values.discount;
  }
  if (values['price.ext.accFlags']) {
    newFilters.discountSubscr = +values['price.ext.accFlags'];
  }
  if (values.stock) {
    newFilters.stock = values.stock;
  }
  if (values.enabled) {
    newFilters.enabled = values.enabled;
  }

  if (values['price.use_stock_price']) {
    newFilters.price_use_stock_price = values['price.use_stock_price'];
  }

  if (values['price.ext.profitMax']) {
    newFilters.profitMax = values['price.ext.profitMax'];
  }

  return newFilters;
}
