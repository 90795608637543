import React, { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { statusFetch } from '../../reducers/dashboard';
import SyncTable from '../../components/SyncTable';
import { AccountStatus } from '../../types/rootState';
import s from './styles.module.scss'


type MarketType = 'g2a' | 'gamivo' | 'ggsel' | 'kinguin';

interface Status {
  error: null | string;
  isLoading: boolean;
  payload: Record<MarketType, AccountStatus[]>;
}

type State = {
  dashboard: {
    status: Status
  }
}

function StatusPage() {
  
  const dispatch = useDispatch()

  const {
    payload:statusPayload,
    isLoading:statusIsLoading
  }: Status = useSelector((state: State) => state?.dashboard?.status, shallowEqual);

  useEffect(() => {
    dispatch(statusFetch());
  }, [])

  const { g2a, gamivo, ggsel, kinguin }: Record<MarketType, AccountStatus[]> = useMemo(() => ({
    g2a: (statusPayload && statusPayload.g2a) || [],
    gamivo: (statusPayload && statusPayload.gamivo) || [],
    ggsel: (statusPayload && statusPayload.ggsel) || [],
    kinguin: (statusPayload && statusPayload.kinguin) || [],
  }), [statusPayload]);

  return (
    <div className={s.statusLayout}>
   <SyncTable
        account="G2A"
        list={g2a}
        loading={statusIsLoading}
    />
    <SyncTable
        account="GAMIVO"
        list={gamivo}
        loading={statusIsLoading}
    />
    <SyncTable
        account="GGSEL"
        list={ggsel}
        loading={statusIsLoading}
    />
    <SyncTable
        account="Kinguin"
        list={kinguin}
        loading={statusIsLoading}
    />
 </div>
 )
}

export default StatusPage;
